import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap-v5';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Footer from '../../components/footer/Footer';
import TopInfoBar from '../../components/top_info_bar/TopInfoBar';
import NavigationBar from '../../components/top_navigation_bar/NavigationBar';
import { Helmet } from 'react-helmet-async';
import Sidebar from '../../components/sidebar/Sidebar';
import { Audio } from 'react-loader-spinner';

const ServiceDetails = () => {
    const { slug } = useParams();
    const [service, setService] = useState({});
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);

    // Load Available services
    useEffect(() => {
        window.axios
            .get("/api/services")
            .then((resp) => {
                if (resp.status === 200) {
                    setServices(resp.data.data);
                }
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data?.message);
                }
            });
    }, []);

    //  Load service details
    useEffect(() => {
        setLoading(true);
        window.axios
            .get(`/api/services/${slug}`)
            .then((resp) => {
                if (resp.status === 200) {
                    setService(resp.data.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast.error("Something went wrong");
                }
            })
            .catch((error) => {
                if (error.response) {
                    setLoading(false);
                    toast.error(error.response.data?.message);
                }
            });
    }, [slug]);
    return (
        <div>
            <Helmet>
                <title>Service - Dhaka Health Care</title>
            </Helmet>
            <TopInfoBar />
            <NavigationBar />
            <Breadcrumb>
                <h1> {service.title} Details</h1>
                <ul className="breadcrumb">
                    <li>
                        <Link to="/" className="d-flex align-items-end">
                            <Icon
                                className="icon"
                                icon="healthicons:home-alt-outline"
                            />
                            Home
                        </Link>
                    </li>
                    <li className="active">Service Details</li>
                </ul>
            </Breadcrumb>
            <div className="container-lg">
                {loading ? (
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "100vh" }}
                    >
                        <Audio
                            height="50"
                            width="50"
                            color="orange"
                            ariaLabel="audio-loading"
                            wrapperStyle={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                            wrapperClass="wrapper-class"
                            visible={true}
                        />
                    </div>
                ) : (
                    <div className="service-details-area pb-5">
                        <div className="row mt-5">
                            {service?.content != null ? (
                                <div className="col-lg-8 col-md-7">
                                    <div
                                        className="services-details-img"
                                        dangerouslySetInnerHTML={{
                                            __html: service.content,
                                        }}
                                    ></div>
                                    <div className="service-details-inner"></div>
                                </div>
                            ) : (
                                <div className="col-lg-8 col-md-7 d-flex justify-content-center align-items-center">
                                    <h4>No Content</h4>
                                </div>
                            )}
                            {/* Sidebar */}
                            <div className="col-lg-4 col-md-5 ">
                                <Sidebar
                                    title={"Services"}
                                    items={services}
                                    path="services"
                                ></Sidebar>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <Footer />
        </div>
    );
};

export default ServiceDetails;