import React from 'react';
import SectionHeader from '../section_header/SectionHeader';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const OurFeatures = () => {

    const mocFeatures = [
        {
            id: "1",
            tooltip: "Ambulance",
            title: " GETTING SAFE EMERGENCY CARE AT OUR Hospital",
            details:
                "The emergency ambulance of Dhaka Healthcare Systems Hospital is always at your doorstep. Our ambulance service is available 24 hour.",
            img: "../image/feature/ambulance.jpg",
            // detailsImages : []
        },
        {
            id: "2",
            tooltip: "MEDICINE",
            title: "MEDICINE AND REHABILITATION CARE",
            details:
                "Our Diagnostic expertise are dedicated to Rehabilitation Doctor’s Consultation, Physiotherapy, Occupational Therapy, Speech Therapy and Rehabilitation Nursing",
            img: "../image/feature/medicine.jpg",
        },
        {
            id: "3",
            tooltip: "Equipments",
            title: "Digital MEDICAL Equipments and Systems",
            details:
                "Our commitment is investing in cutting-edge medical technology and advanced equipment that empowers our medical professionals to deliver accurate treatments",
            img: "../image/feature/feature10.jpg",
        },
        {
            id: "4",
            tooltip: "Cabins",
            title: "WE HAVE THE BEST PATIENT CABINS",
            details:
                "Find out whether you will share a room and how your privacy will be maintained. Ask about the hospital policy on visitors to find out the hours.",
            img: "../image/feature/feature8.jpg",
        },
        {
            id: "5",
            tooltip: "Laboratory",
            title: "Digital Laboratory and Test Reports",
            details:
                "The Laboratory, where a needle is used to take blood from a vein. Usually, this is laboratory for testing Patient Blood and Urine, and the results of these tests",
            img: "../image/feature/feature9.jpg",
        },
        {
            id: "6",
            tooltip: "Waiting Space",
            title: "Waiting Space for patients and Visitors",
            details:
                " As you step into our hospital, you will find the patient waiting space thoughtfully designed to Comfort and well-being of our valued patients and their families.",
            img: "../image/feature/feature14.jpg",
        },
    ];
    return (
        <Container className="py-5">
            <SectionHeader
                title="OUR FEATURE"
                details="While quality care should be a major factor in choosing a hospital, other details — such as Emergency Ambulance, Patient rooms, and Patient Care. We each have different priorities when it comes to choosing a hospital, and some factors may be more important to you than others"
            ></SectionHeader>
            <div className="blog-area full-blog sec-padding">
                <div className="container">
                    <div className="blog-items feature-items">
                        <div className="blog-content">
                            <div className="blog-item-box">
                                <div className="row">
                                    {mocFeatures?.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="col-lg-4 col-md-6 single-item"
                                            >
                                                <div className="item">
                                                    <div className="thumb">
                                                        <img
                                                            src={item.img}
                                                            alt="Thumb"
                                                            className="img-fluid w-100"
                                                        />
                                                        <div className="date">
                                                            {item.tooltip}
                                                        </div>
                                                    </div>
                                                    <div className="info">
                                                        <h4 className="text-uppercase">
                                                            {item.title.length >
                                                            55
                                                                ? `${item.title.slice(
                                                                      0,
                                                                      55
                                                                  )}...`
                                                                : item.title}
                                                        </h4>
                                                        <p>
                                                            {item.details
                                                                .length > 120
                                                                ? `${item.details.slice(
                                                                      0,
                                                                      120
                                                                  )}...`
                                                                : item.details}
                                                        </p>
                                                        <Link
                                                            to={`/feature-details/${item.id}`}
                                                            className="common_btn text-center"
                                                        >
                                                            Read More
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default OurFeatures;