import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Footer from '../../components/footer/Footer';
import TopInfoBar from '../../components/top_info_bar/TopInfoBar';
import NavigationBar from '../../components/top_navigation_bar/NavigationBar';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { Audio } from 'react-loader-spinner';

const BlogDetails = () => {
    const [blogs, setBlogs] = useState([]);
    const { slug } = useParams();
    const [blogDetails, setBlogDetails] = useState({});
    const [loading, setLoading] = useState(true);

    // Load Available Blogs
    useEffect(() => {
        setLoading(true);
        window.axios
            .get(
                "/api/posts?_fields=slug,title,excerpt,fimg_url,featured_media,author,author_name,date&per_page=5"
            )
            .then((resp) => {
                if (resp.status === 200) {
                    console.log(resp.data);
                    setBlogs(resp.data);
                    // setLoading(false);
                } else {
                    setLoading(false);
                    toast.error("Something went wrong");
                }
            })
            .catch((error) => {
                if (error.response) {
                    setLoading(false);
                    toast.error(error.response.data?.message);
                }
            });
    }, []);

    // Load Available Blogs
    useEffect(() => {
        setLoading(true);
        window.axios
            .get(
                "/api/posts?_fields=slug,title,excerpt,fimg_url,featured_media,author,author_name,date&per_page=5"
            )
            .then((resp) => {
                if (resp.status === 200) {
                    console.log(resp.data);
                    setBlogs(resp.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast.error("Something went wrong");
                }
            })
            .catch((error) => {
                if (error.response) {
                    setLoading(false);
                    toast.error(error.response.data?.message);
                }
            });
    }, []);

    // Load Available Blogs
    useEffect(() => {
        setLoading(true);
        window.axios
            .get(`/api/posts?slug=${slug}`)
            .then((resp) => {
                if (resp.status === 200) {
                    console.log(resp.data);
                    setBlogDetails(resp.data[0]);
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast.error("Something went wrong");
                }
            })
            .catch((error) => {
                if (error.response) {
                    setLoading(false);
                    toast.error(error.response.data?.message);
                }
            });
    }, [slug]);
    return (
        <div>
            <Helmet>
                <title>Blog - Dhaka Health Care</title>
            </Helmet>
            <TopInfoBar />
            <NavigationBar />
            <Breadcrumb>
                <h1>Blog Details</h1>
                <ul className="breadcrumb">
                    <li>
                        <Link to="/" className="d-flex align-items-end">
                            <Icon
                                className="icon"
                                icon="healthicons:home-alt-outline"
                            />{" "}
                            Home
                        </Link>
                    </li>
                    <li className="active">
                        <Link to="/blog">Blogs</Link>
                    </li>
                    <li className="active">Blog Details</li>
                </ul>
            </Breadcrumb>
            <div
                className="blog-area single full-blog right-sidebar sec-padding"
                style={{ height: "auto" }}
            >
                <div className="container">
                    {loading ? (
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ height: "100vh" }}
                        >
                            <Audio
                                height="40"
                                width="40"
                                color="orange"
                                ariaLabel="audio-loading"
                                wrapperStyle={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                                wrapperClass="wrapper-class"
                                visible={true}
                            />
                        </div>
                    ) : (
                        <div className="blog-items">
                            <div className="row">
                                <div className="blog-content col-lg-8 col-md-12">
                                    <div className="blog-item-box">
                                        {blogDetails && (
                                        <div className="item">
                                            {/* <!-- Start Post Thumb --> */}
                                            <div className="thumb">
                                                <img
                                                    src={blogDetails.fimg_url}
                                                    alt="Thumb"
                                                    className="img-fluid"
                                                />
                                                <div className="date">
                                                    {blogDetails.date?.slice(
                                                        0,
                                                        10
                                                    )}
                                                </div>
                                            </div>
                                            {/* <!-- Start Post Thumb --> */}

                                            <div className="info">
                                                <h3>
                                                    {
                                                        blogDetails.title
                                                            ?.rendered
                                                    }
                                                </h3>

                                                <div
                                                    className=""
                                                    dangerouslySetInnerHTML={{
                                                        __html: blogDetails
                                                            .content?.rendered,
                                                    }}
                                                ></div>
                                            </div>
                                        </div>
                                        )}
                                    </div>
                                </div>

                                {/* <!-- Start Sidebar --> */}
                                <div className="sidebar col-lg-4 col-md-12">
                                    <aside>
                                        <div className="sidebar-item recent-post">
                                            <div className="title">
                                                <h4>Recent Post</h4>
                                            </div>
                                            <ul>
                                                {blogs?.map((item, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <div className="thumb">
                                                                {/* <a href="#"> */}
                                                                <img
                                                                    src={
                                                                        item.fimg_url
                                                                    }
                                                                    alt="Thumb"
                                                                    className="img-fluid"
                                                                />
                                                                {/* </a> */}
                                                            </div>
                                                            <div className="info">
                                                                <div className="meta-title">
                                                                    <span className="post-date">
                                                                        {item.date?.slice(
                                                                            0,
                                                                            10
                                                                        )}
                                                                    </span>
                                                                </div>
                                                                <Link
                                                                    to={`/blog/${item.slug}`}
                                                                >
                                                                    {
                                                                        item
                                                                            .title
                                                                            ?.rendered
                                                                    }
                                                                </Link>
                                                            </div>
                                                        </li>
                                                    );
                                                })}
                                                
                                            </ul>
                                        </div>
                                        <div className="sidebar-item social-sidebar">
                                            <div className="title">
                                                <h4>follow us</h4>
                                            </div>
                                            <div className="sidebar-info">
                                                <ul className="p-0">
                                                    <li className="">
                                                        <Link
                                                            target="_blank"
                                                            to="https://www.facebook.com/DHSDhaka/"
                                                            className="bg-transparent border-0"
                                                        >
                                                            <Icon
                                                                className="me-2 fs-4"
                                                                icon="logos:facebook"
                                                                link="www.facebook.com/dhsdhaka"
                                                            />
                                                        </Link>
                                                    </li>
                                                    <li className="">
                                                        <Link
                                                            target="_blank"
                                                            to="https://www.instagram.com/dhs.dhaka"
                                                            className="bg-transparent border-0"
                                                        >
                                                            <Icon
                                                                className="me-2 fs-4"
                                                                icon="skill-icons:instagram"
                                                                link="www.instagram.com/dhs.dhaka"
                                                            />
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </aside>
                                </div>
                                {/* <!-- End Start Sidebar --> */}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default BlogDetails;