import { Icon } from '@iconify/react';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Footer from '../../components/footer/Footer';
import TopInfoBar from '../../components/top_info_bar/TopInfoBar';
import NavigationBar from '../../components/top_navigation_bar/NavigationBar';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

const Contact = () => {
    const {
        reset,
        register,
        formState: { errors },
        handleSubmit,
    } = useForm(); // react form hook

    const onSubmit = (data) => {
        window.axios
            .post(`/api/contact`, data)
            .then((resp) => {
                if (resp.status === 204) { 
                    toast.success("Message sent Successfully");
                    reset();
                } else {
                    toast.error('Something went wrong')
                }
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data?.message);
                }
            });
    };
    return (
        <div>
            <Helmet>
                <title>Contact - Dhaka Health Care</title>
            </Helmet>
            <TopInfoBar />
            <NavigationBar />
            <Breadcrumb>
                <h1>Contact Us</h1>
                <ul className="breadcrumb">
                    <li>
                        <Link to="/" className="d-flex align-items-end">
                            <Icon
                                className="icon"
                                icon="healthicons:home-alt-outline"
                            />
                            Home
                        </Link>
                    </li>
                    <li className="active">Contact</li>
                </ul>
            </Breadcrumb>
            <div className="contact-area sec-padding ">
                <div className="container">
                    <div className="contact-items">
                        <div className="row">
                            <div className="col-lg-7 contact-box mb-6">
                                <div className="form-items">
                                    <h5>Contact Us</h5>
                                    <h2>
                                        Drop your message for <br />
                                        any info or question.
                                    </h2>
                                    <form
                                        onSubmit={handleSubmit(onSubmit)}
                                        className="contact-form"
                                    >
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        placeholder="Name"
                                                        type="text"
                                                        {...register("name", {
                                                            required:
                                                                "Name Is Required",
                                                        })}
                                                    />
                                                    {errors?.name && (
                                                        <p role="alert">
                                                            {
                                                                errors?.name
                                                                    .message
                                                            }
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        placeholder="Email*"
                                                        type="email"
                                                        {...register("email", {
                                                            required:
                                                                "Name Is Required",
                                                        })}
                                                    />
                                                    {errors?.email && (
                                                        <p role="alert">
                                                            {
                                                                errors?.email
                                                                    .message
                                                            }
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        placeholder="Phone"
                                                        type="text"
                                                        {...register("phone", {
                                                            required:
                                                                "Phone Is Required",
                                                        })}
                                                    />
                                                    {errors?.phone && (
                                                        <p role="alert">
                                                            {
                                                                errors?.phone
                                                                    .message
                                                            }
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group comments">
                                                    <textarea
                                                        className="form-control"
                                                        placeholder="Your Message*"
                                                        {...register("message", {
                                                            required:
                                                                "Phone Is Required",
                                                        })}
                                                    />
                                                    {errors?.message && (
                                                        <p role="alert">
                                                            {
                                                                errors?.message
                                                                    .message
                                                            }
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <button
                                                    className="common_btn"
                                                    type='submit'
                                                >
                                                    Send Message
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="col-lg-5 left-info">
                                <Row
                                    xs={1}
                                    md={3}
                                    lg={1}
                                    className="info-items pt-3 pt-lg-0"
                                >
                                    {/* <!-- Single Item --> */}
                                    <Col className="item">
                                        <div className="icon">
                                            <Icon
                                                className="icon"
                                                icon="material-symbols:location-on-outline"
                                            />{" "}
                                        </div>
                                        <div className="info">
                                            <h5>Location</h5>
                                            <p>
                                                House # 07, Road-08, Madani
                                                Avenue (100 Feet),
                                                <br /> Vatara, Dhaka-1212.
                                            </p>
                                        </div>
                                    </Col>
                                    {/* <!-- End Single Item --> */}
                                    {/* <!-- Single Item --> */}
                                    <Col className="item">
                                        <div className="icon">
                                            <Icon
                                                className="icon"
                                                icon="tabler:phone-call"
                                            />{" "}
                                        </div>
                                        <div className="info">
                                            <h5>Make a Call</h5>
                                            <p>
                                                +88 02 2222733 <br /> +88 02
                                                2222790
                                            </p>
                                        </div>
                                    </Col>
                                    {/* <!-- End Single Item --> */}
                                    {/* <!-- Single Item --> */}
                                    <Col className="item">
                                        <div className="icon">
                                            <Icon
                                                className="icon"
                                                icon="ic:outline-email"
                                            />{" "}
                                        </div>
                                        <div className="info">
                                            <h5>Send a Mail</h5>
                                            <p>
                                                dhakahealthcaresystems@gmail.com
                                            </p>
                                        </div>
                                    </Col>
                                    {/* <!-- End Single Item --> */}
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="maps-area">
                <div className="google-maps">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3650.5654928677386!2d90.43282459999999!3d23.7984823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c7a6c543790d%3A0xc7381d2b558acb7a!2sDhaka%20Healthcare%20Systems%20Hospital!5e0!3m2!1sen!2sbd!4v1683804822636!5m2!1sen!2sbd"
                        width="100%"
                        height="100%"
                        style={{ border: "0" }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            </div>

            <Footer />
        </div>
    );
};;

export default Contact;