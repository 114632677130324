import React from 'react';

const Breadcrumb = ({children}) => {
    return (
        <div
            className="breadcrumb-area bg-gray text-center shadow dark text-light bg-cover"
            style={{
                backgroundImage: "url(../image/breadcrumb.jpg)",
            }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        {children}                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Breadcrumb;