import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Footer from '../../components/footer/Footer';
import TopInfoBar from '../../components/top_info_bar/TopInfoBar';
import NavigationBar from '../../components/top_navigation_bar/NavigationBar';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { Audio } from 'react-loader-spinner';


const DoctorDetails = () => {
    const {slug} = useParams()
    const navigate = useNavigate()
    const [doctorDetails, setDoctorDetails] = useState({})
    const [loading, setLoading] = useState(true);

    // Load selected Doctor Details
    useEffect(() => {
         setLoading(true);
         window.axios
             .get(`/api/doctor/${slug}`)
             .then((resp) => {
                 if (resp.status === 200) {
                    setLoading(false);
                     setDoctorDetails(resp.data.data);
                 } else {
                    setLoading(false);
                     toast.error("Something went wrong");
                 }
             })
             .catch((error) => {
                 if (error.response) {
                    setLoading(false);
                     toast.error(error.response.data?.message);
                 }
             });
     }, []);
    return (
        <div>
            <Helmet>
                <title>Doctor - Dhaka Health Care</title>
            </Helmet>
            <TopInfoBar />
            <NavigationBar />
            <Breadcrumb>
                <h1>Doctor Details</h1>
                <ul className="breadcrumb">
                    <li>
                        <Link to="/" className="d-flex align-items-end">
                            <Icon
                                className="icon"
                                icon="healthicons:home-alt-outline"
                            />{" "}
                            Home
                        </Link>
                    </li>
                    <li className="active">Doctor Details</li>
                </ul>
            </Breadcrumb>

            <div className="doctors-details-area sec-padding">
                <div className="container">
                    {loading ? (
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ height: "100vh" }}
                        >
                            <Audio
                                height="40"
                                width="40"
                                color="orange"
                                ariaLabel="audio-loading"
                                wrapperStyle={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                                wrapperClass="wrapper-class"
                                visible={true}
                            />
                        </div>
                    ) : (
                        <div className="row">
                            {doctorDetails && (
                                <>
                                    <div className="col-md-6 col-lg-5 col-xl-4">
                                        <div className="doctors-sidebar">
                                            <div className="doctors-sidebar">
                                                <div className="doctors-img bg-white shadow">
                                                    <img
                                                        src={
                                                            doctorDetails.photo
                                                        }
                                                        alt="Image"
                                                        className="img-fluid p-4 pb-0"
                                                    />
                                                </div>
                                                <div className="availability shadow">
                                                    <h3>
                                                        <Icon
                                                            className="fs-4"
                                                            icon="mdi:clock-time-eight-outline"
                                                        />
                                                        Availability
                                                    </h3>
                                                    <ul>
                                                        {doctorDetails?.chamberTime?.map(
                                                            (time, index) => {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {
                                                                            time.day
                                                                        }
                                                                        <span>
                                                                            {
                                                                                time.start_time
                                                                            }{" "}
                                                                            -{" "}
                                                                            {
                                                                                time.end_time
                                                                            }
                                                                        </span>
                                                                    </li>
                                                                );
                                                            }
                                                        )}
                                                        <li>
                                                            <button
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/appointment?doctor=${doctorDetails.slug}`
                                                                    )
                                                                }
                                                                className="common_btn w-100"
                                                            >
                                                                Book an
                                                                appointment
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-7 col-xl-8">
                                        <div className="doctors-details mb-30">
                                            {doctorDetails.name && (
                                                <div className="doctors-history">
                                                    <h2>
                                                        {doctorDetails.name}
                                                    </h2>
                                                    <span>
                                                        {
                                                            doctorDetails.education
                                                        }
                                                    </span>
                                                    <p>{doctorDetails.bio}</p>
                                                    <div className="row borders">
                                                        <div className="col-lg-3 col-md-12 pl-0">
                                                            <div className="left-title">
                                                                <h3>
                                                                    Specialty
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-9 col-md-12 pl-0">
                                                            <div className="right-title">
                                                                <ul>
                                                                    {doctorDetails?.specialty.map(
                                                                        (
                                                                            s,
                                                                            index
                                                                        ) => {
                                                                            return (
                                                                                <li
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                >
                                                                                    <i className="fa-solid fa-hand-point-right"></i>{" "}
                                                                                    {
                                                                                        s
                                                                                    }
                                                                                </li>
                                                                            );
                                                                        }
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row borders">
                                                        <div className="col-lg-3 col-md-12 pl-0">
                                                            <div className="left-title">
                                                                <h3>
                                                                    Education
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-9 col-md-12 pl-0">
                                                            <div className="right-title">
                                                                <ul>
                                                                    {doctorDetails?.educations.map(
                                                                        (
                                                                            e,
                                                                            index
                                                                        ) => {
                                                                            return (
                                                                                <li
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                >
                                                                                    <i className="fa-solid fa-hand-point-right"></i>{" "}
                                                                                    {
                                                                                        e
                                                                                    }
                                                                                </li>
                                                                            );
                                                                        }
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row borders">
                                                        <div className="col-lg-3 col-md-12 pl-0">
                                                            <div className="left-title">
                                                                <h3>
                                                                    Experience
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-9 col-md-12 pl-0">
                                                            <div className="right-title">
                                                                <ul>
                                                                    <li>
                                                                        <i className="fa-solid fa-hand-point-right"></i>{" "}
                                                                        {
                                                                            doctorDetails.experience
                                                                        }
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row borders">
                                                        <div className="col-lg-3 col-md-12 pl-0">
                                                            <div className="left-title">
                                                                <h3>
                                                                    Chamber
                                                                    Address
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-9 col-md-12 pl-0">
                                                            <div className="right-title">
                                                                <ul>
                                                                    <li>
                                                                        <Icon icon="material-symbols:location-on-outline" />{" "}
                                                                        {
                                                                            doctorDetails.chamber
                                                                        }
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row borders">
                                                        <div className="col-lg-3 col-md-12 pl-0">
                                                            <div className="left-title">
                                                                <h3>Phone</h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-9 col-md-12 pl-0">
                                                            <div className="right-title">
                                                                <ul>
                                                                    <li>
                                                                        <Icon icon="tabler:phone-call" />{" "}
                                                                        <a href="tel:+1-(514)-312-5678">
                                                                            {
                                                                                doctorDetails.phone
                                                                            }
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="row borders">
                                            <div className="col-lg-3 col-md-12 pl-0">
                                                <div className="left-title">
                                                    <h3>Social</h3>
                                                </div>
                                            </div>
                                            <div className="col-lg-9 col-md-12 pl-0">
                                                <div className="right-title">
                                                    <ul className="social_icon d-flex">
                                                        <li>
                                                            <Link to="/">
                                                                <Icon
                                                                    className="icon"
                                                                    icon="logos:facebook"
                                                                />
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/">
                                                                <Icon
                                                                    className="icon"
                                                                    icon="logos:linkedin-icon"
                                                                />
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/">
                                                                <Icon
                                                                    className="icon"
                                                                    icon="skill-icons:twitter"
                                                                />
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/">
                                                                <Icon
                                                                    className="icon"
                                                                    icon="skill-icons:instagram"
                                                                />
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div> */}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default DoctorDetails;