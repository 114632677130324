import React, { useEffect, useState } from "react";
import FeatureDoctors from "../components/feature_doctors/FeatureDoctors";
import CallToAction from "../components/call_to_action/CallToAction";
import OurFeatures from "../components/our_features/OurFeatures";
import Testimonial from "../components/testimonial/Testimonial";
import Partners from "../components/partners/Partners";
import Footer from "../components/footer/Footer";
import TopInfoBar from "../components/top_info_bar/TopInfoBar";
import NavigationBar from "../components/top_navigation_bar/NavigationBar";
import { Helmet } from "react-helmet-async";
import HeroArea from "../components/heroArea/HeroArea";
import { Modal } from "react-bootstrap-v5";
import { Audio } from "react-loader-spinner";
import { toast } from "react-toastify";

const Home = () => {
    const [loadingModalShow, setLoadingModalShow] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const [loading, setLoading] = useState(true);
    const [homeData, setHomeData] = useState([]);
    const [popupData, setPopupData] = useState({});

    // Handle Modal Opening and Closing
    useEffect(() => {
        const hasVisitedHomePage = sessionStorage.getItem("hasVisitedHomePage");
        const lastVisitTime = sessionStorage.getItem("lastVisitTime");
        const currentTime = new Date().getTime();

        // Check if the user has visited the home page before
        if (!hasVisitedHomePage) {
            // Show the modal
            // setModalShow(true);
            window.axios
                .get(`/api/popup`)
                .then((resp) => {
                    setPopupData(resp.data.data);
                    setLoadingModalShow(false);
                    setModalShow(true);
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(error.response.data?.message);
                    }
                });               

            // Set session storage to indicate that the user has visited the home page
            sessionStorage.setItem("hasVisitedHomePage", true);
            sessionStorage.setItem("lastVisitTime", currentTime);
        } else {
            // Check the time difference since the last visit
            const timeDifference = currentTime - lastVisitTime;
            if (timeDifference >= 1 * 60 * 1000) {
                // More than 5 minutes have passed since the last visit
                // Show the modal again
                window.axios
                    .get(`/api/popup`)
                    .then((resp) => {
                        setPopupData(resp.data.data);
                        setLoadingModalShow(false);
                        setModalShow(true);
                    })
                    .catch((error) => {
                        if (error.response) {
                            toast.error(error.response.data?.message);
                        }
                    });               

                // Update the last visit time
                sessionStorage.setItem("lastVisitTime", currentTime);
            }
        }
    }, []);

    // Load Home Data
    useEffect(() => {
        setLoading(true);
        window.axios
            .get(`/api/home`)
            .then((resp) => {
                setHomeData(resp.data.data);
                setLoading(false);
                setLoadingModalShow(false);
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data?.message);
                    setLoading(false);
                }
            });
    }, []);


  
    return (
        <>
            <Helmet>
                <title>Dhaka Health Care</title>
            </Helmet>

            {/* Top Info Navbar  */}
            <TopInfoBar />

            {/* Top Navigation Bar */}
            <NavigationBar />

            {/* Loading Modal */}
            <div>
                <Modal
                    show={loadingModalShow}
                    onHide={() => setModalShow(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Body>
                        <div className="">
                            <Audio
                                height="50"
                                width="50"
                                color="white"
                                ariaLabel="audio-loading"
                                wrapperStyle={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                                wrapperClass="wrapper-class"
                                visible={true}
                            />
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

            {/* Home Page Modal */}
            <div className="home-modal">
                <Modal
                    show={!loadingModalShow && modalShow ? true : false}
                    onHide={() => setModalShow(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Body>
                        <img src={popupData?.image} alt="Modal Image" />
                    </Modal.Body>
                </Modal>
            </div>

            {/* Slider Section */}
            <section>
                <HeroArea heroSliders={homeData.slides} />
            </section>

            {/* Feature Doctors Section */}
            <section>
                <FeatureDoctors />
            </section>

            {/* Call To Action */}
            <section>
                <CallToAction />
            </section>

            {/* Our Features Section */}
            <section>
                <OurFeatures />
            </section>

            {/* Testimonials */}
            <section>
                <Testimonial testimonials={homeData?.testimonial} />
            </section>

            {/* Our Partners */}
            <section>
                <Partners partners={homeData.partners} />
            </section>

            {/* Footer */}
            <section>
                <Footer />
            </section>
        </>
    );
};

export default Home;
