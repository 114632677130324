import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap-v5';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Footer from '../../components/footer/Footer';
import TopInfoBar from "../../components/top_info_bar/TopInfoBar";
import NavigationBar from "../../components/top_navigation_bar/NavigationBar";
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import Select from "react-select";
import { Audio } from 'react-loader-spinner';

const Doctors = () => {
    const [doctors, setDoctors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [length, setLength] = useState(15);
    const [paginate, setPaginate] = useState({});
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState("");

    // react form hook
    const {
        reset,
        register,
        setValue,
        control,
        formState: { errors },
        handleSubmit,
        setError,
    } = useForm();

    // Load Available Departments
    useEffect(() => {
        window.axios
            .get("/api/departments")
            .then((resp) => {
                if (resp.status === 200) {                    
                    const departments = resp.data.data.map((dep) => {
                        return {
                            label: dep.name,
                            value: dep.slug,
                        };
                    });
                    setDepartments(departments);
                }
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data?.message);
                }
            });
    }, []);

    // Load available Doctors
    useEffect(() => {
        setLoading(true);
        window.axios
            .get(`/api/doctors?page=${currentPage}&length=${length}`)
            .then((resp) => {
                setPaginate(resp.data.meta);
                setDoctors(resp.data.data);
                setLoading(false);
            })
            .catch((error) => {
                if (error.response) {
                    setLoading(false);
                    toast.error(error.response.data?.message);
                }
            });
    }, []);

    let cancelToken;
    const onSearch = (e) => {
        if (typeof cancelToken !== typeof undefined) {
            cancelToken.cancel("Operation cancelled due to new request.");
        }
        cancelToken = window.axios.CancelToken.source();
        try {
            setLoading(true);
            window.axios
                .get(`/api/doctors?search=${e.target.value}`, {
                    cancelToken: cancelToken.token,
                })
                .then((resp) => {
                    if (resp.status === 200) {
                        setDoctors(resp.data.data);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        toast.error("Something went wrong");
                    }
                });
        } catch (error) {
            setLoading(false);
            toast.error(error.response.data?.message);
        }
    };

    const onSubmit = (data) => {
        setLoading(true)
        window.axios
            .get(`/api/doctors?department_slug=${data.department_slug}`)
            .then((resp) => {              
                if (resp.status === 200) {
                    setDoctors(resp.data.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast.error("Something went wrong");
                }
            })
            .catch((error) => {
                if (error.response) {
                    setLoading(false);
                    toast.error(error.response.data?.message);
                }
            });
    };

    return (
        <>
            <Helmet>
                <title>Doctors - Dhaka Health Care</title>
            </Helmet>
            <TopInfoBar />
            <NavigationBar />
            <div
                className="breadcrumb-area bg-gray text-center shadow dark text-light bg-cover"
                style={{
                    backgroundImage: "url(../image/breadcrumb.jpg)",
                    padding: "40px 0 100px 0",
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <h1>Find Doctor</h1>
                            <ul className="breadcrumb">
                                <li>
                                    <Link
                                        to="/"
                                        className="d-flex align-items-end"
                                    >
                                        <Icon
                                            className="icon"
                                            icon="healthicons:home-alt-outline"
                                        />{" "}
                                        Home
                                    </Link>
                                </li>
                                <li className="active">Doctors</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {/* Search Options */}
            <div className="doctor-search-area">
                <div className="container">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row doctor-search-wrap">
                            <div className="col-sm-12 col-md-4 col-lg-5">
                                <div className="doctor-search-item">
                                    <div className="form-group">
                                        <label>Search By Name</label>
                                        <input
                                            onChange={(e) => onSearch(e)}
                                            type="text"
                                            placeholder="Doctor Name"
                                            className="form-control "
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-5 col-lg-5">
                                <div className="doctor-search-item">
                                    <div className="form-group">
                                        <label>Search By Department</label>
                                        <Controller
                                            name="department_slug"
                                            control={control}
                                            render={({
                                                onChange,
                                                value,
                                                ref,
                                            }) => (
                                                <Select
                                                    isClearable={true}
                                                    placeholder="Select A Department"
                                                    options={departments}
                                                    value={selectedDepartment}
                                                    onChange={(val) => {
                                                        setSelectedDepartment(
                                                            val
                                                        );
                                                        setValue(
                                                            "department_slug",
                                                            val.value
                                                        );
                                                    }}
                                                />
                                            )}
                                            rules={{
                                                required: "This is required",
                                            }}
                                        />
                                    </div>
                                    {errors?.department_slug && (
                                        <p className="text-danger text-sm">
                                            {errors?.department_slug?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-2">
                                <div className="doctor-search-item mx-auto mt-lg-4 mt-0">
                                    <div className="form-group ">
                                        <label></label>
                                        <button
                                            type="submit"
                                            className="search_btn"
                                        >
                                            <Icon
                                                className="me-1"
                                                icon="mingcute:search-line"
                                            />
                                            Search
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            {/* Doctor List */}
            <div className="sec-padding">
                <div className="container">
                    <div className="section-title">
                        <h4>Our Doctors</h4>
                    </div>
                    {loading || doctors?.length <= 0 ? (
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ height: "100vh" }}
                        >
                            {loading ? (
                                <Audio
                                    height="50"
                                    width="50"
                                    color="orange"
                                    ariaLabel="audio-loading"
                                    wrapperStyle={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                    wrapperClass="wrapper-class"
                                    visible={true}
                                />
                            ) : (
                                <p className="text-red">No Doctors Found</p>
                            )}
                        </div>
                    ) : (
                        <Row xs={1} md={3} lg={5} className="g-4">
                            {doctors?.map((doctor, index) => (
                                <Col key={index}>
                                    <div className="p-3 p-lg-0">
                                        <div className="doctor-item">
                                            <div className="doctor-top">
                                                <img
                                                    className="img-fluid feature_doctor_image"
                                                    src={doctor.photo}
                                                    alt="Doctor Photo"
                                                />
                                                <Link
                                                    to={`/appointment?doctor=${doctor.slug}`}
                                                >
                                                    Get Appointment
                                                </Link>
                                            </div>
                                            <div className="doctor-bottom">
                                                <h5>
                                                    <Link
                                                        to={`/doctor/${doctor.slug}`}
                                                    >
                                                        {doctor.name}
                                                    </Link>
                                                </h5>
                                                <h6>{doctor.education}</h6>
                                                <p>
                                                    {doctor.designation} -{" "}
                                                    {doctor.department}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    )}
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Doctors;