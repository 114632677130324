import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import Footer from "../../components/footer/Footer";
import TopInfoBar from "../../components/top_info_bar/TopInfoBar";
import NavigationBar from "../../components/top_navigation_bar/NavigationBar";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { Audio } from "react-loader-spinner";

const FacilitiesDetails = () => {
    const { slug } = useParams();
    const [services, setServices] = useState([]);
    const [facility, setFacility] = useState({});
    const [loading, setLoading] = useState(true);

    // Load Available Departments
    useEffect(() => {        
        window.axios
            .get("/api/services")
            .then((resp) => {
                if (resp.status === 200) {
                    setServices(resp.data.data);
                }
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data?.message);
                }
            });
    }, []);

    const mocFacilities = [
        {
            id: 1,
            title: "BED & ROOM CATEGORIES",
            image: "../image/feature/feature8.jpg",
            detailsImage: ["../image/feature/hospital5.jpg"],
            slug: "bedandrooms",
            description:
                "Our support to the community by offering Health Screening for prevention and detection of diseases saves one from the burden of disease load by early",
        },
        {
            id: 2,
            title: "NURSING CARE",
            image: "../image/feature/medicine.jpg",
            detailsImage: [],
            slug: "nursingcare",
            description:
                "Health Screening for prevention and detection of diseases saves one from the burden of disease load by early",
        },
    ];

    useEffect(() => {
        setLoading(true)
        const facility = mocFacilities.find((item) => item.slug === slug)
        setFacility(facility);
        setLoading(false);
    },[slug])

    return (
        <>
            <Helmet>
                <title>Facility Details - Dhaka Health Care</title>
            </Helmet>
            <TopInfoBar />
            <NavigationBar />
            <Breadcrumb>
                <h1>Facility Details</h1>
                <ul className="breadcrumb">
                    <li>
                        <Link to="/" className="d-flex align-items-end">
                            <Icon
                                className="icon"
                                icon="healthicons:home-alt-outline"
                            />{" "}
                            Home
                        </Link>
                    </li>
                    <li className="active">
                        <Link to="/facilities">Facilities</Link>
                    </li>
                    <li className="active">Facility Details</li>
                </ul>
            </Breadcrumb>
            {/* <div className=""> */}
            <div
                className="facilities-area single full-blog right-sidebar sec-padding"
                style={{ height: "auto" }}
            >
                <div className="container">
                    {loading ? (
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ height: "100vh" }}
                        >
                            <Audio
                                height="40"
                                width="40"
                                color="orange"
                                ariaLabel="audio-loading"
                                wrapperStyle={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                                wrapperClass="wrapper-class"
                                visible={true}
                            />
                        </div>
                    ) : (
                        <div className="facilities-items">
                            <div className="row">
                                {/* facility content */}
                                <div className="facilities-content col-lg-8 col-md-12 mb-4 ">
                                    <div className="facilities-item-box">
                                        {facility && (
                                            <div className="item">
                                                {/* <!-- Start Post Thumb --> */}
                                                <div className="thumb">
                                                    <img
                                                        src={facility.image}
                                                        alt="Thumb"
                                                        className="img-fluid w-100"
                                                    />
                                                </div>
                                                {/* <!-- Start Post Thumb --> */}

                                                <div className="info">
                                                    <h3>{facility.title}</h3>

                                                    <p>
                                                        {facility.description}
                                                    </p>
                                                    {/* <h4>
                                                Conduct replied off led whether
                                                any shortly why arrived adapted
                                            </h4> */}
                                                    <div
                                                        className="row gallery"
                                                        style={{
                                                            marginTop: "10px",
                                                        }}
                                                    >
                                                        {facility?.detailsImage?.map(
                                                            (item, index) => {
                                                                return (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                        className="col-lg-6 col-md-6"
                                                                    >
                                                                        <img
                                                                            src={
                                                                                item
                                                                            }
                                                                            alt="Thumb"
                                                                            className="img-fluid"
                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Right Sidebar  */}
                                <div className="col-lg-4 col-md-8 ">
                                    <div
                                        className="p-3 rounded-2"
                                        style={{
                                            backgroundColor: "#f4f4f4",
                                            height: "660px",
                                        }}
                                    >
                                        <div className="promo-box-bg home-emergency">
                                            <div className="promo-service-top row">
                                                <img
                                                    className="img-fluid img-responsive promo-service-img me-0 col-5 col-md-6"
                                                    src="../image/abbulance.jpg"
                                                />
                                                <div className="emergency-rightsidebar col-7 col-md-6 ">
                                                    <p className="amb-caller">
                                                        <Link to="/services/emergency">
                                                            Emergency?
                                                        </Link>
                                                    </p>
                                                    <p className="amb-call-ambulance">
                                                        Call Ambulance <br />
                                                    </p>
                                                    <p className="amb-call-number">
                                                        +88 02 222296733
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sidebar-item">
                                            <h5 className="category-list-link open-cat-list">
                                                select Departments{" "}
                                                <i
                                                    id="cat-arrow"
                                                    className="fa fa-angle-down"
                                                ></i>
                                            </h5>
                                            <ul
                                                className="services-list list-unstyled p-2"
                                                style={{
                                                    height: "400px",
                                                    overflowY: "scroll",
                                                }}
                                            >
                                                {services?.map((dep, index) => {
                                                    return (
                                                        <li
                                                            key={index}
                                                            className="d-block w-full"
                                                        >
                                                            <Link
                                                                className="active d-block w-full"
                                                                to={`/services/${dep.slug}`}
                                                            >
                                                                {dep.title}
                                                            </Link>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* </div> */}

            <Footer />
        </>
    );
};

export default FacilitiesDetails;
