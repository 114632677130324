import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = ({ items, title, path }) => {
    console.log(items)
    return (
        <div
            className="p-3 rounded-2"
            style={{
                backgroundColor: "#f4f4f4",
                height: "660px",
            }}
        >
            <div className="promo-box-bg home-emergency">
                <Link to="tel:+88 02 222296733">
                    <div className="promo-service-top row">
                        <img
                            className="img-fluid img-responsive promo-service-img me-0 col-5 col-md-6"
                            src="../image/abbulance.jpg"
                        />
                        <div className="emergency-rightsidebar col-7 col-md-6 me-0 ps-0">
                            <span className="amb-caller">Emergency?</span>
                            <p className="amb-call-ambulance">
                                Call Ambulance <br />
                            </p>
                            <p className="amb-call-number">+88 02 222296733</p>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="sidebar-item">
                <h5 className="category-list-link open-cat-list">
                    select {title}{" "}
                    <i id="cat-arrow" className="fa fa-angle-down"></i>
                </h5>
                <ul
                    className="services-list list-unstyled p-2"
                    style={{
                        height: "400px",
                        overflowY: "scroll",
                    }}
                >
                    {items?.map((item, index) => {
                        return (
                            <li key={index} className="d-block w-full">
                                <Link
                                    className="active d-block w-full"
                                    to={`/${path}/${item.slug}`}
                                >
                                    {item.title || item.name}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;