import React, { useEffect, useRef, useState } from 'react';
import Footer from '../../components/footer/Footer';
import TopInfoBar from '../../components/top_info_bar/TopInfoBar';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import Isotope from 'isotope-layout';
import { Col, Container, Row } from 'react-bootstrap-v5';
import NavigationBar from '../../components/top_navigation_bar/NavigationBar';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { Audio } from 'react-loader-spinner';

const Gallery = () => {
    // category = [ "*", "team", "doctor", "hospital", "event", "history", "patient"]

    // init one ref to store the future isotope object
    const isotope = useRef();
    // store the filter keyword in a state
    const [gallery, setGallery] = useState([]);
    const [filterKey, setFilterKey] = useState("*");
    const [loading, setLoading] = useState(false);

    // Load Available Gallery
    useEffect(() => {
        setLoading(true);
        window.axios
            .get(
                "/api/gallery?_fields=slug,title,fimg_url,featured_media,tags&per_page=100"
            )
            .then((resp) => {
                if (resp.status === 200) {
                    // console.log(resp.data);
                    setGallery(resp.data);
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
                if (error.response) {
                    toast.error(error.response.data?.message);
                }
            });
    }, []);

    // initialize an Isotope object with configs
    useEffect(() => {
        isotope.current = new Isotope(".filter-container", {
            itemSelector: ".filter-item",
            layoutMode: "fitRows",
        });
        // cleanup
        return () => isotope.current.destroy();
    }, [gallery]);

    // handling filter key change
    useEffect(() => {
        filterKey === "*"
            ? isotope.current.arrange({ filter: `*` })
            : isotope.current.arrange({ filter: `.${filterKey}` });
    }, [filterKey]);

    const handleFilterKeyChange = (key) => () => setFilterKey(key);
    return (
        <>
            <Helmet>
                <title>Gallery - Dhaka Health Care</title>
            </Helmet>
            <TopInfoBar />
            <NavigationBar />
            <Breadcrumb>
                <h1>Photo Gallery</h1>
                <ul className="breadcrumb">
                    <li>
                        <Link to="/" className="d-flex align-items-end">
                            <Icon
                                className="icon"
                                icon="healthicons:home-alt-outline"
                            />{" "}
                            Home
                        </Link>
                    </li>
                    <li className="active">Gallery</li>
                </ul>
            </Breadcrumb>

            <Container>
                <div className="gallery_contain">
                    <div className="section-title">
                        <h3>Our Gallery</h3>
                    </div>
                    <Row xs={1} md={3} lg="auto" className="gallery_filters">
                        <Col>
                            <button
                                
                                onClick={handleFilterKeyChange("*")}
                            >
                                Show All
                            </button>
                        </Col>
                        <Col>
                            <button
                                
                                onClick={handleFilterKeyChange("tag_15")}
                            >
                                Our Team
                            </button>
                        </Col>
                        <Col>
                            <button
                                
                                onClick={handleFilterKeyChange("tag_16")}
                            >
                                Our Doctors
                            </button>
                        </Col>
                        <Col>
                            <button
                                
                                onClick={handleFilterKeyChange("tag_19")}
                            >
                                Our Patient
                            </button>
                        </Col>
                        <Col>
                            <button
                                
                                onClick={handleFilterKeyChange("tag_17")}
                            >
                                Our Hospital
                            </button>
                        </Col>
                        <Col>
                            <button
                                
                                onClick={handleFilterKeyChange("tag_18")}
                            >
                                Events
                            </button>
                        </Col>
                        <Col>
                            <button onClick={handleFilterKeyChange("tag_20")}>
                                History
                            </button>
                        </Col>
                    </Row>
                    <ul className="filter-container p-0 mx-auto mt-4">                        
                        {loading || gallery?.length <= 0 ? (
                            <div className="d-flex justify-content-center align-items-center">
                                {loading ? (
                                    <Audio
                                        height="40"
                                        width="40"
                                        color="orange"
                                        ariaLabel="audio-loading"
                                        wrapperStyle={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                        wrapperClass="wrapper-class"
                                        visible={true}
                                    />
                                ) : (
                                    <h2>No Picture Available</h2>
                                )}
                            </div>
                        ) : (
                            gallery?.map((pic, index) => {
                                return (
                                    <li key={index}>
                                        <div className={`filter-item tag_${pic.tags[0]}`}>
                                            <img
                                                className="img-fluid"
                                                src={pic.fimg_url}
                                                alt="Gallery Image"
                                            />
                                        </div>
                                    </li>
                                );
                            })
                        )}
                    </ul>

                    {/* <ul className="filter-container  p-0 mx-auto mt-4">
                        <li>
                            <div className="filter-item team">
                                <img
                                    className="img-fluid"
                                    src="../image/gallery/team1.jpg"
                                    alt=""
                                />
                            </div>
                        </li>
                        <li>
                            <div className="filter-item team">
                                <img
                                    className="img-fluid"
                                    src="../image/gallery/team2.jpg"
                                    alt=""
                                />
                            </div>
                        </li>
                        <li>
                            <div className="filter-item team">
                                <img
                                    className="img-fluid"
                                    src="../image/gallery/team3.jpg"
                                    alt=""
                                />
                            </div>
                        </li>
                        <li>
                            <div className="filter-item team">
                                <img
                                    className="img-fluid"
                                    src="../image/gallery/team4.jpg"
                                    alt=""
                                />
                            </div>
                        </li>
                        <li>
                            <div className="filter-item team">
                                <img
                                    className="img-fluid"
                                    src="../image/gallery/team5.jpg"
                                    alt=""
                                />
                            </div>
                        </li>
                        <li>
                            <div className="filter-item team">
                                <img
                                    className="img-fluid"
                                    src="../image/gallery/team6.jpg"
                                    alt=""
                                />
                            </div>
                        </li>
                        <li>
                            <div className="filter-item team">
                                <img
                                    className="img-fluid"
                                    src="../image/gallery/team7.jpg"
                                    alt=""
                                />
                            </div>
                        </li>
                        <li>
                            <div className="filter-item doctor">
                                <img
                                    className="img-fluid"
                                    src="../image/gallery/doctor1.jpg"
                                    alt=""
                                />
                            </div>
                        </li>
                        <li>
                            <div className="filter-item doctor">
                                <img
                                    className="img-fluid"
                                    src="../image/gallery/doctor2.jpg"
                                    alt=""
                                />
                            </div>
                        </li>
                        <li>
                            <div className="filter-item history">
                                <img
                                    className="img-fluid"
                                    src="../image/gallery/history1.jpg"
                                    alt=""
                                />
                            </div>
                        </li>
                        <li>
                            <div className="filter-item event">
                                <img
                                    className="img-fluid"
                                    src="../image/gallery/event1.jpg"
                                    alt=""
                                />
                            </div>
                        </li>
                        <li>
                            <div className="filter-item hospital">
                                <img
                                    className="img-fluid"
                                    src="../image/feature/feature11.jpg"
                                    alt=""
                                />
                            </div>
                        </li>
                        <li>
                            <div className="filter-item hospital">
                                <img
                                    className="img-fluid"
                                    src="../image/feature/feature12.jpg"
                                    alt=""
                                />
                            </div>
                        </li>
                        <li>
                            <div className="filter-item hospital">
                                <img
                                    className="img-fluid"
                                    src="../image/feature/feature10.jpg"
                                    alt=""
                                />
                            </div>
                        </li>
                        <li>
                            <div className="filter-item hospital">
                                <img
                                    className="img-fluid"
                                    src="../image/feature/feature8.jpg"
                                    alt=""
                                />
                            </div>
                        </li>
                        <li>
                            <div className="filter-item hospital">
                                <img
                                    className="img-fluid"
                                    src="../image/feature/feature9.jpg"
                                    alt=""
                                />
                            </div>
                        </li>
                        <li>
                            <div className="filter-item hospital">
                                <img
                                    className="img-fluid"
                                    src="../image/feature/feature14.jpg"
                                    alt=""
                                />
                            </div>
                        </li>
                        <li>
                            <div className="filter-item hospital">
                                <img
                                    className="img-fluid"
                                    src="../image/feature/hospital1.jpg"
                                    alt=""
                                />
                            </div>
                        </li>
                        <li>
                            <div className="filter-item hospital">
                                <img
                                    className="img-fluid"
                                    src="../image/feature/hospital2.jpg"
                                    alt=""
                                />
                            </div>
                        </li>
                        <li>
                            <div className="filter-item hospital">
                                <img
                                    className="img-fluid"
                                    src="../image/feature/hospital3.jpg"
                                    alt=""
                                />
                            </div>
                        </li>
                        <li>
                            <div className="filter-item hospital">
                                <img
                                    className="img-fluid"
                                    src="../image/feature/hospital4.jpg"
                                    alt=""
                                />
                            </div>
                        </li>
                        <li>
                            <div className="filter-item hospital">
                                <img
                                    className="img-fluid"
                                    src="../image/feature/hospital5.jpg"
                                    alt=""
                                />
                            </div>
                        </li>
                    </ul> */}
                </div>
            </Container>
            <Footer />
        </>
    );
};

export default Gallery;