import React, { useEffect, useState } from 'react';
import TopInfoBar from "../../components/top_info_bar/TopInfoBar";
import NavigationBar from "../../components/top_navigation_bar/NavigationBar";
import Footer from "../../components/footer/Footer";
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
const Login = () => {
    const [loading, setLoading] = useState(false)
    const {
        register,
        setError,
        formState: { errors },
        handleSubmit,
    } = useForm(); // react form hook

    useEffect(() => {
        window.axios2.get("/api/user").then((response) => {
            if (response.status === 200) {
                window.location = "/login";
            }
        });
    }, [])

    const onSubmit = (data) => {
        setLoading(true)
        window.axios2.get("/sanctum/csrf-cookie").then((response) => {
            window.axios2
                .post("/login", data, { maxRedirects: 0 })
                .then((rep) => {
                    if (rep.status === 200) {
                        setLoading(false)
                        toast.success('Login Successfully')
                        console.log(rep.data.url);
                        window.location = rep.data.url;  
                        
                    }
                })
                .catch((error) => {
                    setError("password", {
                        type: "wrong_info",
                        message: error.response.data.errors.email,
                    });
                    setLoading(false)
                });
            
        });
    }
    return (
        <>
            <Helmet>
                <title>Login - Dhaka Health Care</title>
            </Helmet>
            <TopInfoBar />
            <NavigationBar />
            <Breadcrumb>
                <h1>Login</h1>
                <ul className="breadcrumb">
                    <li>
                        <Link to="/" className="d-flex align-items-end">
                            <Icon
                                className="icon"
                                icon="healthicons:home-alt-outline"
                            />{" "}
                            Home
                        </Link>
                    </li>
                    <li className="active">Login</li>
                </ul>
            </Breadcrumb>

            <div className="login_form">
                <div className="form-items bg-white px-4 py-5 rounded-4 border border-3 shadow">
                    <h2>Login </h2>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="contact-form"
                    >
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        placeholder="Email*"
                                        type="email"
                                        {...register("email", {
                                            required: "Email Must Be Required",
                                        })}
                                    />
                                    {errors.email === "required" && (
                                        <p role="alert">
                                            {errors.email.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        placeholder="Password"
                                        type="password"
                                        {...register("password", {
                                            required: "Password Must Be Required",
                                        })}
                                    />
                                    {errors.password && (
                                        <p className="text-danger">
                                            {errors.password.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <button
                                    type="submit"
                                    className="common_btn w-100 d-block"
                                >
                                    {loading ? (
                                        <div
                                            className="spinner-border text-light"
                                            role="status"
                                        >
                                            <span className="visually-hidden">
                                                Loading...
                                            </span>
                                        </div>
                                    ) : (
                                        "Login"
                                    )}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <Footer />
        </>
    );
};;

export default Login;