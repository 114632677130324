import React, { useEffect, useState } from 'react';
import SectionHeader from '../section_header/SectionHeader';
import Card from 'react-bootstrap/Card';
import { Container } from 'react-bootstrap';
import Slider from "react-slick";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const FeatureDoctors = () => {
    const navigate = useNavigate();
    const [featureDoctors, setFeatureDoctors] = useState([])

    useEffect(() => {
        window.axios
            .get(`/api/doctors?is_featured=1&length=15`)
            .then((resp) => {                
                if (resp.status === 200) {
                    setFeatureDoctors(resp.data.data);
                } else {
                    toast.error("Something went wrong");
                }
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data?.message);
                }
            });
    },[])

    const settings = {
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        autoPlay: true,
        nextArrow: <></>,
        prevArrow: <></>,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <Container className="pt-2 pb-5 py-lg-5 bg-green-200">
            <SectionHeader
                title="FEATURE DOCTORS"
                url = "/find-a-doctor"
                details="At Dhaka Healthcare System Hospital, we are proud to have a team of highly skilled and compassionate doctors dedicated to providing top-notch medical care to our patients  Our doctor team experienced and specialized physicians, each committed to delivering personalized and comprehensive healthcare solutions."
                button_text="Meet Our Consultant"
            ></SectionHeader>
            <div className="doctor_card_contain slide_custom_dots">
                {featureDoctors.length > 0 && (
                    <Slider {...settings}>
                        {featureDoctors?.map((doctor, index) => {
                            return (
                                <div key={index}>
                                    <Link to={`doctor/${doctor.slug}`} className='text-dark'>
                                        <Card
                                            className="active"
                                        >
                                            <Card.Img
                                                className="img-fluid feature_doctor_image"
                                                variant="top"
                                                src={doctor.photo}
                                                alt="Doctor"
                                            />
                                            <Card.Body>
                                                <h6 className="text-uppercase">
                                                    {doctor.name}
                                                </h6>
                                                <Card.Subtitle>
                                                    {doctor.education}
                                                </Card.Subtitle>
                                                <Card.Text>
                                                    {doctor.designation}
                                                    {" - "}
                                                    {doctor.department}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </div>
                            );
                        })}
                    </Slider>
                )}
            </div>
        </Container>
    );
};

export default FeatureDoctors;