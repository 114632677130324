import React, { useRef, useState, useEffect } from 'react';
import Slider from "react-slick";
import { Icon } from "@iconify/react";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap-v5";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

const HeroArea = ({ heroSliders }) => {
    const sliderRef = useRef();
    const navigate = useNavigate();

    // Slider Properties
    const settings = {
        fade: true,
        autoplay: true,
        autoplaySpeed: 4500,
        pauseOnHover: false,
        infinite: true,
        speed: 1900,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <></>,
        prevArrow: <></>,
        responsive: [
            {
                breakpoint: 1024,
                settings: {},
            },
            {
                breakpoint: 768,
                settings: {
                    fade: false,
                    dots: true,
                    speed: 1000,
                    // autoplaySpeed: 2000,
                },
            },
        ],
    };

    const previousButton = () => {
        sliderRef.current.slickPrev();
    };
    return (
        <div className="position-relative hero_section">
            <Slider ref={sliderRef} {...settings}>
                {heroSliders &&
                    heroSliders.map((slide, index) => {
                        return (
                            <div key={index}>
                                <div
                                    className="hero-image px-2 px-md-3 px-lg-3 px-xl-3 px-xxl-2 d-flex align-items-center img-fluid"
                                    style={{
                                        backgroundImage: `url(${slide.image})`
                                    }}
                                >
                                    <button
                                        className="slide_control_btn"
                                        onClick={previousButton}
                                    >
                                        <Icon icon="uil:angle-left-b" />
                                    </button>
                                    <Container>
                                        <div className="hero_area_content">
                                        </div>
                                    </Container>
                                    <button
                                        className="slide_control_btn"
                                        onClick={previousButton}
                                    >
                                        <Icon icon="uil:angle-right-b" />
                                    </button>
                                </div>
                            </div>
                        );
                    })}
            </Slider>
            <Container className="position-relative features_container mx-auto">
                <Row>
                    <Col xs={12} md={4} className="p-0">
                        <Link
                            to="/appointment"
                            className="feature justify-content-start text-white"
                        >
                            <Icon
                                className="feature_icon"
                                icon="healthicons:doctor-male-outline"
                            />
                            <div className="feature_body">
                                <h5>BOOK ONLINE APPOINTMENT</h5>
                                <p className="feature_link">
                                    View More
                                    <Icon
                                        className="ms-2 fs-5"
                                        icon="mdi:arrow-right-thin-circle-outline"
                                    />
                                </p>
                            </div>
                        </Link>
                    </Col>
                    <Col xs={12} md={4} id="second_feature" className="p-0">
                        <Link
                            to="/find-a-doctor"
                            className="feature justify-content-start text-white"
                        >
                            <Icon
                                className="feature_icon"
                                icon="ic:round-date-range"
                            />
                            <div className="feature_body">
                                <h5>LOOKING FOR A CONSULTANT</h5>
                                <p className="feature_link">
                                    View More
                                    <Icon
                                        className="ms-2 fs-5"
                                        icon="mdi:arrow-right-thin-circle-outline"
                                    />
                                </p>
                            </div>
                        </Link>
                    </Col>
                    <Col xs={12} md={4} className="p-0">
                        <Link
                            to="/contact"
                            className="feature justify-content-start text-white"
                        >
                            <Icon
                                className="feature_icon"
                                icon="healthicons:ambulance-outline"
                            />
                            <div className="feature_body">
                                <h5>EMERGENCY AMBULANCE</h5>
                                <p className="feature_link">
                                    View More
                                    <Icon
                                        className="ms-2 fs-5"
                                        icon="mdi:arrow-right-thin-circle-outline"
                                    />
                                </p>
                            </div>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default HeroArea;