import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Footer from '../../components/footer/Footer';
import TopInfoBar from '../../components/top_info_bar/TopInfoBar';
import NavigationBar from '../../components/top_navigation_bar/NavigationBar';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Select from "react-select";
import { Audio } from 'react-loader-spinner';

const Appointment = () => {
    let [searchParams] = useSearchParams();
    const [searchDoctor, setSearchDoctor] = useState(searchParams.get("doctor"));

    const [doctors, setDoctors] = useState([]);
    const [doctorSchedule, setDoctorSchedule] = useState([]);

    const [departments, setDepartments] = useState([]);
    const [appointmentTimes, setAppointmentTimes] = useState([]);

    const [isAppointmentSuccess, setIsAppointmentSuccess] = useState(false);

    const [selectedDepartment, setSelectedDepartment] = useState("");
    const [selectedDoctor, setSelectedDoctor] = useState("");
    const [selectedAppointmentTime, setSelectedAppointmentTime] = useState("");
    const [selectedGender, setSelectedGender] = useState("");

     const [loading, setLoading] = useState(true);

    // react form hook
    const {
        reset,
        register,
        setValue,
        control,
        formState: { errors },
        handleSubmit,
        setError,
    } = useForm();
    
    // Load selected Doctor Details and set as form data
    useEffect(() => {
        setLoading(true);
        if (searchDoctor) {
            window.axios
                .get(`/api/appointmentCreate`, {
                    params: { doctor: searchDoctor }
                })
                .then((resp) => {
                    if (resp.status === 200) {
                        const data = resp.data;
                        setDoctorSchedule(data.chamberTime)                        
                        
                        const appointmentTimes =  data.chamberTime.map((item, index) => {
                            return {
                                label: item.label + ', ' + item.time,
                                value: item.value
                            }
                        })
                        setAppointmentTimes(appointmentTimes);

                        setSelectedDepartment(data.department);
                        setValue("department_id", data.department.value);

                        setSelectedDoctor(data.doctor);
                        setValue("doctor_id", data.doctor.value);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        toast.error("Something went wrong");
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        setLoading(false);
                        toast.error(error.response.data?.message);
                    }
                });
        } else {
            window.axios
                .get(`/api/appointmentCreate`)
                .then((resp) => {
                    if (resp.status === 200) {
                        setDepartments(resp.data.departments);
                        setDepartments(resp.data.departments);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        toast.error("Something went wrong");
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        setLoading(false);
                        toast.error(error.response.data?.message);
                    }
                });
        }    
    }, [searchDoctor]);    

    const onDepartmentChange = (id) => {
        setSelectedDoctor("")
        setDoctorSchedule([])
        if (id) {
            window.axios
                .get(`/api/appointmentCreate`, {
                    params: {
                        department_id: id
                    }
                })
                .then((resp) => {
                    if (resp.status === 200) {
                        const data = resp.data;         
                        setDoctors(data.doctors);
                    } else {
                        toast.error("Something went wrong");
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(error.response.data?.message);
                    }
                });
        }
    }

    const onDoctorChange = (doctor) => {
        if (doctor) {
            window.axios
                .get(`/api/appointmentCreate`, {
                    params: {
                        doctor: doctor.slug,
                    },
                })
                .then((resp) => {
                    if (resp.status === 200) {
                        const data = resp.data;
                        setDoctorSchedule(data.chamberTime);
                        const appointmentTimes = data.chamberTime.map(
                            (item) => {
                                return {
                                    label: item.label + ", " + item.time,
                                    value: item.value,
                                };
                            }
                        );
                        setAppointmentTimes(appointmentTimes);
                    } else {
                        toast.error("Something went wrong");
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(error.response.data?.message);
                    }
                });
        }
    }

    const onSubmit = (data) => {
        if (data) {
            setLoading(true);
            window.axios
                .post(`/api/appointmentCreate`, data)
                .then((resp) => {
                    if (resp.status === 204) {
                        setIsAppointmentSuccess(!isAppointmentSuccess);
                        toast.success("Book Appointment successfully");
                        setLoading(false);
                    } else {
                        setLoading(false);
                        toast.error("Something went wrong");
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        setLoading(false);
                        toast.error(error.response.data?.message);
                    }
                });
        }
    };
    return (
        <>
            <Helmet>
                <title>Appointment - Dhaka Health Care</title>
            </Helmet>
            <TopInfoBar />
            <NavigationBar />
            <Breadcrumb>
                <h1>Doctor Appointment</h1>
                <ul className="breadcrumb">
                    <li>
                        <Link to="/" className="d-flex align-items-end">
                            <Icon
                                className="icon"
                                icon="healthicons:home-alt-outline"
                            />{" "}
                            Home
                        </Link>
                    </li>
                    <li className="active">
                        <Link
                            to="/find-a-doctor"
                            className="d-flex align-items-end"
                        >
                            Find A Doctor
                        </Link>
                    </li>
                    <li className="active">Appointment</li>
                </ul>
            </Breadcrumb>
            <div className="appointment-area sec-padding">
                <div className="container">
                    {loading ? (
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ height: "100vh" }}
                        >
                            <Audio
                                height="50"
                                width="50"
                                color="orange"
                                ariaLabel="audio-loading"
                                wrapperStyle={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                                wrapperClass="wrapper-class"
                                visible={true}
                            />
                        </div>
                    ) : (
                        <div className="row align-items-center appointment-wrap-two">
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-7">
                                {!isAppointmentSuccess && (
                                    <div className="appointment-item appointment-item-two">
                                        <div className="appointment-shape">
                                            <img
                                                src="../image/3.png"
                                                alt="Shape"
                                            />
                                        </div>
                                        <h2>Book your appointment</h2>
                                        <span>
                                            We will confirm your appointment
                                            within 2 hours
                                        </span>
                                        <div className="appointment-form">
                                            <form
                                                onSubmit={handleSubmit(
                                                    onSubmit
                                                )}
                                            >
                                                <div className="row">
                                                    {/* Name Input */}
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>Name</label>
                                                            <input
                                                                type="text"
                                                                placeholder="Enter Your Name"
                                                                className="form-control"
                                                                {...register(
                                                                    "name",
                                                                    {
                                                                        required:
                                                                            "Name is Required",
                                                                    }
                                                                )}
                                                            />
                                                            {errors?.name && (
                                                                <p className="text-danger text-sm">
                                                                    {
                                                                        errors
                                                                            ?.name
                                                                            ?.message
                                                                    }
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {/* Age */}
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <i className="fal fa-envelope"></i>
                                                            <label>Age</label>
                                                            <input
                                                                type="text"
                                                                placeholder="Enter Your Age"
                                                                className="form-control"
                                                                {...register(
                                                                    "age",
                                                                    {
                                                                        required:
                                                                            "Age is Required",
                                                                    }
                                                                )}
                                                            />
                                                            {errors?.age && (
                                                                <p className="text-danger text-sm">
                                                                    {
                                                                        errors
                                                                            ?.age
                                                                            ?.message
                                                                    }
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {/* Gender */}
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>
                                                                Gender *
                                                            </label>
                                                            <Controller
                                                                name="gender"
                                                                control={
                                                                    control
                                                                }
                                                                render={({
                                                                    onChange,
                                                                    value,
                                                                    ref,
                                                                }) => (
                                                                    <Select
                                                                        options={[
                                                                            {
                                                                                label: "Male",
                                                                                value: "Male",
                                                                            },
                                                                            {
                                                                                label: "Female",
                                                                                value: "Female",
                                                                            },
                                                                            {
                                                                                label: "Others",
                                                                                value: "Others",
                                                                            },
                                                                        ]}
                                                                        placeholder="Select Gender"
                                                                        value={
                                                                            selectedGender
                                                                        }
                                                                        onChange={(
                                                                            val
                                                                        ) => {
                                                                            setSelectedGender(
                                                                                val
                                                                            );
                                                                            setValue(
                                                                                "gender",
                                                                                val.value
                                                                            );
                                                                        }}
                                                                    />
                                                                )}
                                                                rules={{
                                                                    required:
                                                                        "This is required",
                                                                }}
                                                            />
                                                            {errors?.gender && (
                                                                <p className="text-danger text-sm">
                                                                    {
                                                                        errors
                                                                            ?.gender
                                                                            ?.message
                                                                    }
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {/* Phone */}
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <i className="fal fa-phone"></i>
                                                            <label>Phone</label>
                                                            <input
                                                                type="text"
                                                                placeholder="Enter Your Number"
                                                                className="form-control"
                                                                {...register(
                                                                    "phone",
                                                                    {
                                                                        required:
                                                                            "Phone is Required",
                                                                    }
                                                                )}
                                                            />
                                                            {errors?.phone && (
                                                                <p className="text-danger text-sm">
                                                                    {
                                                                        errors
                                                                            ?.phone
                                                                            ?.message
                                                                    }
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {/* Department */}
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>
                                                                Department *
                                                            </label>
                                                            <Controller
                                                                name="department_id"
                                                                control={
                                                                    control
                                                                }
                                                                render={({
                                                                    onChange,
                                                                    value,
                                                                    ref,
                                                                }) => (
                                                                    <Select
                                                                        placeholder="Select Department"
                                                                        isDisabled={
                                                                            searchDoctor &&
                                                                            true
                                                                        }
                                                                        options={
                                                                            departments
                                                                        }
                                                                        value={
                                                                            selectedDepartment
                                                                        }
                                                                        onChange={(
                                                                            val
                                                                        ) => {
                                                                            setSelectedDepartment(
                                                                                val
                                                                            );
                                                                            setValue(
                                                                                "department_id",
                                                                                val.value
                                                                            );
                                                                            onDepartmentChange(
                                                                                val.value
                                                                            );
                                                                        }}
                                                                    />
                                                                )}
                                                                rules={{
                                                                    required:
                                                                        "Department is required",
                                                                }}
                                                            />
                                                            {errors?.department_id && (
                                                                <p className="text-danger text-sm">
                                                                    {
                                                                        errors
                                                                            ?.department_id
                                                                            ?.message
                                                                    }
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {/* Doctor */}
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>
                                                                Doctor *
                                                            </label>
                                                            <Controller
                                                                name="doctor_id"
                                                                control={
                                                                    control
                                                                }
                                                                render={({
                                                                    onChange,
                                                                    value,
                                                                    ref,
                                                                }) => (
                                                                    <Select
                                                                        isDisabled={
                                                                            selectedDepartment ===
                                                                                "" ||
                                                                            (searchDoctor &&
                                                                                true)
                                                                        }
                                                                        options={
                                                                            doctors
                                                                        }
                                                                        placeholder={
                                                                            doctors.length <=
                                                                            0
                                                                                ? "No Options"
                                                                                : "Select Doctor"
                                                                        }
                                                                        value={
                                                                            selectedDoctor
                                                                        }
                                                                        onChange={(
                                                                            val
                                                                        ) => {
                                                                            setSelectedDoctor(
                                                                                val
                                                                            );
                                                                            setValue(
                                                                                "doctor_id",
                                                                                val.value
                                                                            );
                                                                            onDoctorChange(
                                                                                val
                                                                            );
                                                                        }}
                                                                    />
                                                                )}
                                                                rules={{
                                                                    required:
                                                                        "Doctor is required",
                                                                }}
                                                            />
                                                            {errors?.doctor_id && (
                                                                <p className="text-danger text-sm">
                                                                    {
                                                                        errors
                                                                            ?.doctor_id
                                                                            ?.message
                                                                    }
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {/* Date */}
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>
                                                                Date *
                                                            </label>
                                                            <div className="">
                                                                <input
                                                                    className="date-picker-input"
                                                                    type="date"
                                                                    id="birthday"
                                                                    {...register(
                                                                        "date",
                                                                        {
                                                                            required:
                                                                                "Date is Required",
                                                                        }
                                                                    )}
                                                                />
                                                                {errors?.date && (
                                                                    <p className="text-danger text-sm">
                                                                        {
                                                                            errors
                                                                                ?.date
                                                                                .message
                                                                        }
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* time */}
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>
                                                                Time *
                                                            </label>
                                                            <Controller
                                                                name="chamber_time_id"
                                                                control={
                                                                    control
                                                                }
                                                                render={({
                                                                    onChange,
                                                                    value,
                                                                    ref,
                                                                }) => (
                                                                    <Select
                                                                        isDisabled={
                                                                            selectedDoctor ===
                                                                                "" &&
                                                                            true
                                                                        }
                                                                        options={
                                                                            appointmentTimes
                                                                        }
                                                                        placeholder={
                                                                            selectedDoctor ===
                                                                                "" ||
                                                                            appointmentTimes?.length <=
                                                                                0
                                                                                ? "No Options"
                                                                                : "Select Time"
                                                                        }
                                                                        value={
                                                                            selectedAppointmentTime
                                                                        }
                                                                        onChange={(
                                                                            val
                                                                        ) => {
                                                                            setSelectedAppointmentTime(
                                                                                val
                                                                            );
                                                                            setValue(
                                                                                "chamber_time_id",
                                                                                val.value
                                                                            );
                                                                        }}
                                                                    />
                                                                )}
                                                                rules={{
                                                                    required:
                                                                        "Time is required",
                                                                }}
                                                            />
                                                            {errors?.chamber_time_id && (
                                                                <p className="text-danger text-sm">
                                                                    {
                                                                        errors
                                                                            ?.chamber_time_id
                                                                            ?.message
                                                                    }
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-center">
                                                    <button
                                                        type="submit"
                                                        className="common_btn"
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                )}
                                {isAppointmentSuccess && (
                                    <div className="appointment-item-two">
                                        <div className="appointment-success-box">
                                            <div className="box-header">
                                                <Icon
                                                    icon="ep:success-filled"
                                                    color="#00c570"
                                                    className="success-icon"
                                                />
                                                <div className="box-header">
                                                    Congratulation
                                                </div>
                                            </div>
                                            <div className="box-body">
                                                <p className="fs-6">
                                                    Your Appointment has been
                                                    booked successfully
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-5">
                                <div className="appointment-item-two-right">
                                    <div className="appointment-item-content">
                                        <h2>Working Hours</h2>
                                        {doctorSchedule.length > 0 ? (
                                            <>
                                                <div className="content-one">
                                                    <ul>
                                                        {doctorSchedule?.map(
                                                            (time, index) => {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {
                                                                            time.label
                                                                        }
                                                                    </li>
                                                                );
                                                            }
                                                        )}
                                                    </ul>
                                                </div>
                                                <div className="content-two">
                                                    <ul>
                                                        {doctorSchedule?.map(
                                                            (time, index) => {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {
                                                                            time.time
                                                                        }
                                                                    </li>
                                                                );
                                                            }
                                                        )}
                                                    </ul>
                                                </div>
                                            </>
                                        ) : (
                                            <p>No Schedule Available</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Appointment;