import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Footer from '../../components/footer/Footer';
import TopInfoBar from '../../components/top_info_bar/TopInfoBar';
import NavigationBar from '../../components/top_navigation_bar/NavigationBar';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { Audio } from 'react-loader-spinner';
const Blog = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(false);

    const mocBlogs = [
        // {
        //     id: "1",
        //     title: 'Lt. Col. (retd) Dr. Syed Abtahi joined as “Director (Hospital)"',
        //     details:
        //         "Lt. Col. (retd) Dr. Syed Abtahi joined as “Director (Hospital)” on 1st March 2023. He obtained his MBBS degree from Sir Salimullah Medical College, Dhaka. Later he got post graduation from Thailand and MPH (Hospital Management) degree from University.",
        //     img: "../image/media/media3.jpg",
        //     tooltip: "15 July, 2023",
        // },
        {
            id: "2",
            title: "DHSH conducting necessary dengue tests every day",
            details:
                "Do you suspect you have been infected with dengue fever? Wondering where, when, what to test? Dhaka Healthcare Systems Hospital is conducting necessary dengue tests every day of the week and results are available within 12-24 hours.",
            img: "../image/media/media1.jpg",
            tooltip: "15 July, 2023",
        },
        {
            id: "3",
            title: "Celebrating 52th Years of freedom",
            details:
                "From an Emancipation Proclamation, we got the inspiration to wrest freedom. That spirit still gives us the courage to break all the barriers and take firm steps toward improvement. So we move forward, like birds flying in the open sky.",
            img: "../image/media/media2.jpg",
            tooltip: "26 March 2023",
        },
        {
            id: "4",
            title: "Free medical care program organized as month of victory",
            details:
                '"Dhaka Healthcare Systems Hospital in Human Services" Some stills of the free medical care program organized during the month  of victory',
            img: "../image/media/media8.jpg",
            tooltip: "31 December 2022",
        },
        {
            id: "5",
            title: "A Special Staff Meeting was held with the aim of making quality service",
            details:
                "A special meeting was held with our staff to address all work and services conducted in Dhaka Healthcare Systems in order to uphold consistent quality service for the patients.",
            img: "../image/media/media6.jpg",
            tooltip: "14 November 2022",
        },
        {
            id: "6",
            title: "Free medical care on National Day of Mourning",
            details:
                '"Dhaka Healthcare Systems Hospital in Human Services" Some stills of the free medical care program organized on the occasion of the National Day of Mourning',
            img: "../image/media/media7.jpg",
            tooltip: "16 August 2022",
        },
    ];

    // Load Available Blogs
    useEffect(() => {
        setLoading(true);
        window.axios
            .get(
                "/api/posts?_fields=slug,title,excerpt,fimg_url,featured_media,author,author_name,date&per_page=5"
            )
            .then((resp) => {
                if (resp.status === 200) {
                    console.log(resp.data);
                    setBlogs(resp.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast.error('Something went wrong');
                }
            })
            .catch((error) => {
                if (error.response) {
                    setLoading(false);
                    toast.error(error.response.data?.message);
                }
            });
    }, []);

    const data = [
        {
            date: "2023-09-19T11:20:55",
            slug: "dhsh-conducting-necessary-dengue-tests-every-day",
            title: {
                rendered: "DHSH conducting necessary dengue tests every day",
            },
            excerpt: {
                rendered:
                    "Do you suspect you have been infected with dengue fever? Wondering where, when, what to test? Dhaka Healthcare Systems Hospital is conducting necessary dengue tests every day of the week and results are available within 12-24 hours.",
                protected: false,
            },
            author: 1,
            featured_media: 107,
            fimg_url:
                "https://blog.dhaka-health.com/wp-content/uploads/2023/09/media1.jpg",
            author_name: "admin",
        },
    ];

    return (
        <>
            <Helmet>
                <title>Media - Dhaka Health Care</title>
            </Helmet>
            <TopInfoBar />
            <NavigationBar />
            <Breadcrumb>
                <h1>Blogs Grid</h1>
                <ul className="breadcrumb">
                    <li>
                        <Link to="/" className="d-flex align-items-end">
                            <Icon
                                className="icon"
                                icon="healthicons:home-alt-outline"
                            />{" "}
                            Home
                        </Link>
                    </li>
                    <li className="active">Blogs</li>
                </ul>
            </Breadcrumb>
            <div className="blog-area full-blog sec-padding">
                <div className="container">
                    <div className="blog-items feature-items">
                        <div className="blog-content">
                            <div className="blog-item-box">
                                <div className="row">
                                    {/* Single Item */}
                                    {loading || blogs?.length <= 0 ? (
                                        <div className="d-flex justify-content-center align-items-center" style={{height: "100vh"}}>
                                            {loading ? (
                                                <Audio
                                                    height="40"
                                                    width="40"
                                                    color="orange"
                                                    ariaLabel="audio-loading"
                                                    wrapperStyle={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                    wrapperClass="wrapper-class"
                                                    visible={true}
                                                />
                                            ) : (
                                                <h2>No Data Available</h2>
                                            )}
                                        </div>
                                    ) : (
                                        blogs?.map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="col-lg-4 col-md-6 single-item"
                                                >
                                                    <div
                                                        className="item"
                                                        style={{
                                                            height: "500px",
                                                        }}
                                                    >
                                                        <div className="thumb">
                                                            <img
                                                                src={
                                                                    item.fimg_url
                                                                }
                                                                alt="Thumb"
                                                                className="img-fluid"
                                                            />
                                                            <div className="date">
                                                                {item.date?.slice(
                                                                    0,
                                                                    10
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="info">
                                                            <h4>
                                                                <Link
                                                                    to={`/blog/${item.slug}`}
                                                                >
                                                                    {item.title?.rendered?.length > 80
                                                                        ? `${item.title.rendered.slice(
                                                                              0,
                                                                              80
                                                                          )}...`
                                                                        : item.title?.rendered}
                                                                </Link>
                                                            </h4>
                                                            <p>
                                                                <span>
                                                                    {item
                                                                        .excerpt?.rendered?.length > 120
                                                                        ? `${item.excerpt.rendered.slice(
                                                                              0,
                                                                              120
                                                                          )}...`
                                                                        : item.excerpt?.rendered}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    )}
                                </div>

                                {/* <!-- Pagination --> */}
                                {/* <div className="row">
                                    <div className="col-md-12 pagi-area text-center">
                                        <nav aria-label="navigation">
                                            <ul className="pagination">
                                                <li className="page-item">
                                                    <a
                                                        className="page-link"
                                                        href="#"
                                                    >
                                                        <Icon icon="ic:twotone-keyboard-arrow-left" />
                                                    </a>
                                                </li>
                                                <li className="page-item active">
                                                    <a
                                                        className="page-link"
                                                        href="#"
                                                    >
                                                        1
                                                    </a>
                                                </li>
                                                <li className="page-item">
                                                    <a
                                                        className="page-link"
                                                        href="#"
                                                    >
                                                        2
                                                    </a>
                                                </li>
                                                <li className="page-item">
                                                    <a
                                                        className="page-link"
                                                        href="#"
                                                    >
                                                        3
                                                    </a>
                                                </li>
                                                <li className="page-item">
                                                    <a
                                                        className="page-link"
                                                        href="#"
                                                    >
                                                        <Icon icon="ic:outline-keyboard-arrow-right" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Blog;