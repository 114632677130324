import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap-v5";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import TopInfoBar from "../../components/top_info_bar/TopInfoBar";
import NavigationBar from "../../components/top_navigation_bar/NavigationBar";
import { Helmet } from "react-helmet-async";
import Footer from "../../components/footer/Footer";
import { Audio } from "react-loader-spinner";

const Services = () => {
    const [services, serServices] = useState([]);
    const [loading, setLoading] = useState(true);

    // load available services
    useEffect(() => {
        setLoading(true);
        window.axios
            .get("/api/services")
            .then((resp) => {
                if (resp.status === 200) {
                    serServices(resp.data.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast.error("Something went wrong");
                }
            })
            .catch((error) => {
                if (error.response) {
                    setLoading(false);
                    toast.error(error.response.data?.message);
                }
            });
    }, []);
    
    return (
        <div>
            <Helmet>
                <title>Services - Dhaka Health Care</title>
            </Helmet>
            <TopInfoBar />
            <NavigationBar />
            <Breadcrumb>
                <h1>Our Services</h1>
                <ul className="breadcrumb">
                    <li>
                        <Link to="/" className="d-flex align-items-end">
                            <Icon
                                className="icon"
                                icon="healthicons:home-alt-outline"
                            />{" "}
                            Home
                        </Link>
                    </li>
                    <li className="active">Services</li>
                </ul>
            </Breadcrumb>

            <Container className="py-5 services">
                <div className="section-title">
                    <h3>Our Services</h3>
                </div>
                {loading || services?.length <= 0 ? (
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "100vh" }}
                    >
                        {loading ? (
                            <Audio
                                height="50"
                                width="50"
                                color="orange"
                                ariaLabel="audio-loading"
                                wrapperStyle={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                                wrapperClass="wrapper-class"
                                visible={true}
                            />
                        ) : (
                            <p className="text-red">No Services Found</p>
                        )}
                    </div>
                ) : (
                    <div className="services_list">
                        <div className="d-block w-100">
                            <ul>
                                {services.map((service, index) => {
                                    return (
                                        <li key={index}>
                                            <Link
                                                to={`/services/${service.slug}`}
                                                className="service_link"
                                            >
                                                {service.title}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                )}
            </Container>

            <Footer />
        </div>
    );
};

export default Services;
