import React from 'react';
import { Container } from "react-bootstrap";
import SectionHeader from "../section_header/SectionHeader";
import Card from "react-bootstrap/Card";
import Slider from 'react-slick';

const Testimonial = ({ testimonials }) => {

    const settings = {
        dots: true,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        nextArrow: <></>,
        prevArrow: <></>,
        // infinite: true,
        // autoplay: true,
        // autoplaySpeed: 4000,
        // cssEase: "linear",
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <div>
            <Container className="pb-5">
                <SectionHeader
                    title="OUR TESTIMONIALS"
                    details="At Dhaka Healthcare System Hospital, our patients' satisfaction and well-being are at the heart of everything we do. We take great pride in providing exceptional healthcare services, and nothing brings us more joy than receiving positive feedback from those we've had the privilege to serve."
                ></SectionHeader>
                <div className="slide_custom_dots">
                    <Slider {...settings}>
                        {testimonials?.map((item, idx) => (
                            <div key={idx} className="testimonial_card_contain">
                                <Card className="shadow">
                                    <img
                                        className="testimonial_img"
                                        src={item.image}
                                        alt=""
                                    />
                                    <Card.Body>
                                        <Card.Text>
                                            {item.description !== null
                                                ? item.description.length > 250
                                                    ? item.description.substring(
                                                          0,
                                                          249
                                                      ) + "..."
                                                    : item.description
                                                : "No Content"}
                                        </Card.Text>
                                        <Card.Title>{item.name}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                        ))}
                    </Slider>
                </div>

                {/* <div className="testimonial_card_contain">
                    <Row xs={1} md={2} className="gy-5">
                        {testimonials?.map((item, idx) => (
                            <Col key={idx}>
                                <Card className="shadow">
                                    <img
                                        className="testimonial_img"
                                        src={item.image}
                                        alt=""
                                    />
                                    <Card.Body>
                                        <Card.Text>
                                            {item.description}
                                        </Card.Text>
                                        <Card.Title>{item.name}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div> */}
            </Container>
        </div>
    );
};

export default Testimonial;