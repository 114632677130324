import { Icon } from "@iconify/react";
import React from "react";
import { Container } from "react-bootstrap";
import { Nav, Navbar } from "react-bootstrap-v5";
import { NavLink } from "react-router-dom";

const TopInfoBar = () => {
    return (
        <div className="top_info_bar">
            <Container>
                <Navbar
                    variant="white"
                    className="px-0 flex-column flex-md-row justify-content-start"
                >
                    <Nav className="top_info_nav me-auto">
                        <NavLink
                            to="/about"
                            style={({ isActive }) => ({
                                color: isActive ? "#ff5722" : "white",
                            })}
                            className="nav-link ps-0"
                        >
                            ABOUT
                        </NavLink>
                        <NavLink
                            to="/blog"
                            style={({ isActive }) => ({
                                color: isActive ? "#ff5722" : "white",
                            })}
                            className="nav-link"
                        >
                            MEDIA
                        </NavLink>
                        <NavLink
                            to="/career"
                            style={({ isActive }) => ({
                                color: isActive ? "#ff5722" : "white",
                            })}
                            className="nav-link"
                        >
                            CAREER
                        </NavLink>
                        <NavLink
                            to="/gallery"
                            style={({ isActive }) => ({
                                color: isActive ? "#ff5722" : "white",
                            })}
                            className="nav-link"
                        >
                            GALLERY
                        </NavLink>
                        <NavLink
                            to="/contact"
                            style={({ isActive }) => ({
                                color: isActive ? "#ff5722" : "white",
                            })}
                            className="nav-link"
                        >
                            CONTACT
                        </NavLink>
                    </Nav>
                    <div className="top_info_contact d-md-flex me-auto me-md-0">
                        <p className="mb-xs-2 pb-1 p-md-0">
                            <Icon icon="ph:phone" className="contact_icon " />
                            +88 02 222296733
                        </p>
                        <p className="ml-md-2 pb-1 p-md-0">
                            <Icon
                                icon="mdi:email-outline"
                                className="contact_icon"
                            />
                            dhakahealthcaresystems@gmail.com
                        </p>
                    </div>
                </Navbar>
            </Container>
        </div>
    );
};

export default TopInfoBar;
