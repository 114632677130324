import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div id="main">
            <div className="fof">
                <h1>Error 404</h1>
                <p className="text-danger fs-6 fw-bold">Page Not Found</p>
                <Link to="/">
                    Back To Home
                </Link>
            </div>
        </div>
    );
};

export default NotFound;