import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Footer from '../../components/footer/Footer';
import TopInfoBar from '../../components/top_info_bar/TopInfoBar';
import NavigationBar from "../../components/top_navigation_bar/NavigationBar";
import Slider from 'react-slick';
import { Card, Col, Row } from 'react-bootstrap-v5';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import Sidebar from '../../components/sidebar/Sidebar';
import { Audio } from 'react-loader-spinner';

const DepartmentDetails = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [department, setDepartment] = useState({});
    const [doctors, setDoctors] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [loading, setLoading] = useState(true);

    // Load Available Departments
    useEffect(() => {
        setLoading(true);
        window.axios
            .get("/api/departments")
            .then((resp) => {
                if (resp.status === 200) {
                    setDepartments(resp.data.data);
                    setLoading(false);
                }
            })
            .catch((error) => {
                if (error.response) {
                    setLoading(false);
                    toast.error(error.response.data?.message);
                }
            });
    }, []);

    const settings = {
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        autoPlay: true,
        nextArrow: <></>,
        prevArrow: <></>,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        // responsive: [
        //     {
        //         breakpoint: 1024,
        //         settings: {
        //             slidesToShow: 3,
        //             slidesToScroll: 1,
        //             infinite: true,
        //             dots: true,
        //         },
        //     },
        //     {
        //         breakpoint: 768,
        //         settings: {
        //             slidesToShow: 3,
        //             slidesToScroll: 1,
        //             initialSlide: 1,
        //             infinite: true,
        //             dots: true,
        //         },
        //     },
        //     {
        //         breakpoint: 479,
        //         settings: {
        //             slidesToShow: 1,
        //             slidesToScroll: 1,
        //             initialSlide: 1,
        //             // infinite: true,
        //             dots: true,
        //         },
        //     },
        // ],
    };

    // Load department Details
    useEffect(() => {
        setLoading(true);
        window.axios
            .get(`/api/departments/${slug}`)
            .then((resp) => {
                if (resp.status === 200) {
                    setDepartment(resp.data.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast.error("Something went wrong");
                }
            })
            .catch((error) => {
                if (error.response) {
                    setLoading(false);
                    toast.error(error.response.data?.message);
                }
            });
    }, [slug]);

    // Load available Doctors
    useEffect(() => {
        window.axios
            .get(`/api/doctors`)
            .then((resp) => {
                setDoctors(resp.data.data);
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data?.message);
                }
            });
    }, []);
    return (
        <div>
            <Helmet>
                <title>Department - Dhaka Health Care</title>
            </Helmet>
            <TopInfoBar />
            <NavigationBar />
            <Breadcrumb>
                <h1>{department.name} Details</h1>
                <ul className="breadcrumb">
                    <li>
                        <Link to="/" className="d-flex align-items-end">
                            <Icon
                                className="icon"
                                icon="healthicons:home-alt-outline"
                            />{" "}
                            Home
                        </Link>
                    </li>
                    <li className="active">Department Details</li>
                </ul>
            </Breadcrumb>
            <div className="department-details-area">
                <div className="department-details-content">
                    <div className="container-lg department-top-content">
                        {loading ? (
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ height: "100vh" }}
                            >
                                <Audio
                                    height="40"
                                    width="40"
                                    color="orange"
                                    ariaLabel="audio-loading"
                                    wrapperStyle={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                    wrapperClass="wrapper-class"
                                    visible={true}
                                />
                            </div>
                        ) : (
                            <>
                                {department && (
                                    <div className="row g-4">
                                        {/* Department Details */}
                                        {department?.content != null ? (
                                            <div
                                                className="col-lg-8 col-md-7 department-details"
                                                dangerouslySetInnerHTML={{
                                                    __html: department?.content,
                                                }}
                                            ></div>
                                        ) : (
                                            <div className="col-lg-8 col-md-7 d-flex justify-content-center align-items-center">
                                                <h4>No Content</h4>
                                            </div>
                                        )}
                                        {/* Emergency Ambulance Sidebar */}
                                        <div className="col-lg-4 col-md-5 rounded-2">
                                            <Sidebar
                                                title={"Department"}
                                                items={departments}
                                                path='departments'
                                            ></Sidebar>
                                        </div>
                                    </div>
                                )}

                                {/* Doctors Slider */}
                                <div className="doctor_card_contain slide_custom_dots px-0">
                                    {doctors?.length > 0 && (
                                        <Slider {...settings}>
                                            {doctors.map((doctor, index) => {
                                                return (
                                                    <div key={index}>
                                                        <Link
                                                            to={`/doctor/${doctor.slug}`}
                                                            className="text-dark"
                                                        >
                                                            <Card className="active">
                                                                <Card.Img
                                                                    className="img-fluid feature_doctor_image"
                                                                    variant="top"
                                                                    src={
                                                                        doctor.photo
                                                                    }
                                                                    alt="Doctor"
                                                                />
                                                                <Card.Body>
                                                                    <h6 className="text-uppercase">
                                                                        {
                                                                            doctor.name
                                                                        }
                                                                    </h6>
                                                                    <Card.Subtitle>
                                                                        {
                                                                            doctor.education
                                                                        }
                                                                    </Card.Subtitle>
                                                                    <Card.Text>
                                                                        {
                                                                            doctor.designation
                                                                        }
                                                                        {" - "}
                                                                        {
                                                                            doctor.department
                                                                        }
                                                                    </Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        </Link>
                                                    </div>
                                                );
                                            })}
                                        </Slider>
                                    )}
                                </div>

                                {/* Action Link Buttons */}
                                <div className="department_link_area">
                                    <div>
                                        <button
                                            onClick={() =>
                                                navigate("/find-a-doctor")
                                            }
                                            className="page_link"
                                        >
                                            <Icon icon="solar:map-arrow-right-linear" />{" "}
                                            Find A Doctor
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            onClick={() =>
                                                navigate("/appointment")
                                            }
                                            className="page_link"
                                        >
                                            <Icon icon="solar:map-arrow-right-linear" />{" "}
                                            Get Appointment
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            onClick={() => navigate("/contact")}
                                            className="page_link"
                                        >
                                            <Icon icon="solar:map-arrow-right-linear" />{" "}
                                            Our Location Map
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default DepartmentDetails;