import { BrowserRouter } from "react-router-dom";
import DefinedWebRoutes from "./DefinedWebRoutes";
import ReactDOM from "react-dom/client";
import ScrollToTop from "./components/scroolToTop/ScrollToTop";
import { HelmetProvider } from "react-helmet-async";

function User() {
    return (
        <HelmetProvider>
            <BrowserRouter>
                <ScrollToTop />
                <DefinedWebRoutes></DefinedWebRoutes>
            </BrowserRouter>
        </HelmetProvider>
    );
}
export default User;
// DOM element

if (document.getElementById("react-app")) {
    const root = ReactDOM.createRoot(document.getElementById("react-app"));
    root.render(<User />);
}
