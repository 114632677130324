import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Col, Collapse, Row } from "react-bootstrap-v5";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, NavLink } from "react-router-dom";
import { toast } from "react-toastify";

const NavigationBar = () => {
    const [serviceMenuCollapse, setServiceMenuCollapse] = useState(false);
    const [departmentMenuCollapse, setDepartmentMenuCollapse] = useState(false);
    const [patientMenuCollapse, setPatientMenuCollapse] = useState(false);

    const [services, serServices] = useState([]);
    const [departments, setDepartments] = useState([]);

    // load available services
    useEffect(() => {
        window.axios
            .get("/api/services")
            .then((resp) => {
                if (resp.status === 200) {
                    serServices(resp.data.data);
                }
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data?.message);
                }
            });
    }, []);

    // Load Available Departments
    useEffect(() => {
        window.axios
            .get("/api/departments")
            .then((resp) => {
                if (resp.status === 200) {
                    setDepartments(resp.data.data);
                }
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data?.message);
                }
            });
    }, []);

    return (
        <>
            <Container className="">
                <Navbar
                    id="top_navigation"
                    expand="lg"
                    bg="white"
                    variant="light"
                    className="p-0"
                >
                    <Navbar.Brand className="top_navigation_brand">
                        <Link to="/">
                            <img
                                src="../image/DHS Logo.png"
                                alt="MDB Logo"
                                loading="lazy"
                            />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Item>
                                <NavLink to="/" className="nav-link">
                                    Home
                                </NavLink>
                            </Nav.Item>
                            <Nav.Item className="mega_menu d-none d-lg-block">
                                <NavLink to="/services" className="nav-link">
                                    Services
                                    <Icon icon="octicon:triangle-down-24" />
                                </NavLink>
                                <div id="mega_drop_down">
                                    <div className="d-block w-100">
                                        <Row xs={1} md={2} lg={4} gap={3}>
                                            <Col className="drop_down_content">
                                                <ul>
                                                    {services?.map(
                                                        (item, index) => {
                                                            if (index < 7) {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <Link
                                                                            to={`/services/${item.slug}`}
                                                                            className="link"
                                                                        >
                                                                            {
                                                                                item.title
                                                                            }
                                                                        </Link>
                                                                    </li>
                                                                );
                                                            }
                                                        }
                                                    )}
                                                </ul>
                                            </Col>
                                            <Col className="drop_down_content">
                                                <ul>
                                                    {services?.map(
                                                        (item, index) => {
                                                            if (
                                                                index >= 7 &&
                                                                index < 14
                                                            ) {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <Link
                                                                            to={`/services/${item.slug}`}
                                                                            className="link"
                                                                        >
                                                                            {
                                                                                item.title
                                                                            }
                                                                        </Link>
                                                                    </li>
                                                                );
                                                            }
                                                        }
                                                    )}
                                                </ul>
                                            </Col>
                                            <Col className="drop_down_content">
                                                <ul>
                                                    {services?.map(
                                                        (item, index) => {
                                                            if (
                                                                index >= 14 &&
                                                                index < 21
                                                            ) {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <Link
                                                                            to={`/services/${item.slug}`}
                                                                            className="link"
                                                                        >
                                                                            {
                                                                                item.title
                                                                            }
                                                                        </Link>
                                                                    </li>
                                                                );
                                                            }
                                                        }
                                                    )}
                                                </ul>
                                            </Col>
                                            <Col className="drop_down_content">
                                                <ul>
                                                    {services?.map(
                                                        (item, index) => {
                                                            if (
                                                                index >= 21 &&
                                                                index < 28
                                                            ) {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <Link
                                                                            to={`/services/${item.slug}`}
                                                                            className="link"
                                                                        >
                                                                            {
                                                                                item.title
                                                                            }
                                                                        </Link>
                                                                    </li>
                                                                );
                                                            }
                                                        }
                                                    )}
                                                </ul>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Nav.Item>
                            {/* menu for tablet and mobile */}
                            <Nav.Item className="d-lg-none">
                                <Link
                                    className="nav-link"
                                    onClick={() =>
                                        setServiceMenuCollapse(
                                            !serviceMenuCollapse
                                        )
                                    }
                                    aria-controls="mega_menu_collapse"
                                    aria-expanded={serviceMenuCollapse}
                                >
                                    Services{" "}
                                    <Icon icon="octicon:triangle-down-24" />
                                </Link>
                                <Collapse in={serviceMenuCollapse}>
                                    <div
                                        id="mega_menu_collapse"
                                        className="px-3"
                                    >
                                        <div id="mega_drop_down">
                                            <div className="d-block w-100">
                                                <Row
                                                    xs={1}
                                                    md={2}
                                                    lg={4}
                                                    gap={3}
                                                    className=""
                                                >
                                                    <Col className="drop_down_content">
                                                        <ul>
                                                            {services?.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    if (
                                                                        index <
                                                                        7
                                                                    ) {
                                                                        return (
                                                                            <li
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                <Link
                                                                                    to={`/services/${item.slug}`}
                                                                                    className="link"
                                                                                >
                                                                                    {
                                                                                        item.title
                                                                                    }
                                                                                </Link>
                                                                            </li>
                                                                        );
                                                                    }
                                                                }
                                                            )}
                                                        </ul>
                                                        {/* <ul>
                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Appointment
                                                                    Desk{" "}
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Birth
                                                                    Certificate
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Blood Bank
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Cafeteria
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Chemotherapy{" "}
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Corporate
                                                                    Client{" "}
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Day Care{" "}
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Death
                                                                    Certificate{" "}
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Departments-Services-Location{" "}
                                                                </Link>
                                                            </li>
                                                        </ul> */}
                                                    </Col>
                                                    <Col className="drop_down_content">
                                                        <ul>
                                                            {services?.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    if (
                                                                        index >=
                                                                            7 &&
                                                                        index <
                                                                            14
                                                                    ) {
                                                                        return (
                                                                            <li
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                <Link
                                                                                    to={`/services/${item.slug}`}
                                                                                    className="link"
                                                                                >
                                                                                    {
                                                                                        item.title
                                                                                    }
                                                                                </Link>
                                                                            </li>
                                                                        );
                                                                    }
                                                                }
                                                            )}
                                                        </ul>
                                                        {/* <ul>
                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Dietician{" "}
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Discharge
                                                                    Protocol{" "}
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Doorstep
                                                                    Sample
                                                                    Collection
                                                                    Packages And
                                                                    Home Sample
                                                                    Collection
                                                                    Service
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Emergency{" "}
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Emergency
                                                                    Stroke
                                                                    Management{" "}
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Evening OPD{" "}
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Home Sample
                                                                    Collection
                                                                    Service{" "}
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Hospital
                                                                    Location Map{" "}
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Investigation{" "}
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Medical
                                                                    Records{" "}
                                                                </Link>
                                                            </li>
                                                        </ul> */}
                                                    </Col>
                                                    <Col className="drop_down_content">
                                                        <ul>
                                                            {services?.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    if (
                                                                        index >=
                                                                            14 &&
                                                                        index <
                                                                            21
                                                                    ) {
                                                                        return (
                                                                            <li
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                <Link
                                                                                    to={`/services/${item.slug}`}
                                                                                    className="link"
                                                                                >
                                                                                    {
                                                                                        item.title
                                                                                    }
                                                                                </Link>
                                                                            </li>
                                                                        );
                                                                    }
                                                                }
                                                            )}
                                                        </ul>
                                                        {/* <ul>
                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Online
                                                                    payment
                                                                    Information{" "}
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Pharmacy{" "}
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Physiotherapy{" "}
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Prayer Room{" "}
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Psychosocial
                                                                    Counseling{" "}
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Report
                                                                    Delivery{" "}
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Room
                                                                    Category{" "}
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Vaccination{" "}
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link
                                                                    to="/service-details/1"
                                                                    className="link"
                                                                >
                                                                    Visiting
                                                                    Hours &amp;
                                                                    Rules{" "}
                                                                </Link>
                                                            </li>
                                                        </ul> */}
                                                    </Col>
                                                    <Col className="drop_down_content">
                                                        <ul>
                                                            {services?.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    if (
                                                                        index >=
                                                                            21 &&
                                                                        index <
                                                                            28
                                                                    ) {
                                                                        return (
                                                                            <li
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                <Link
                                                                                    to={`/services/${item.slug}`}
                                                                                    className="link"
                                                                                >
                                                                                    {
                                                                                        item.title
                                                                                    }
                                                                                </Link>
                                                                            </li>
                                                                        );
                                                                    }
                                                                }
                                                            )}
                                                        </ul>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </Collapse>
                            </Nav.Item>

                            <Nav.Item className="mega_menu d-none d-lg-block">
                                <NavLink className="nav-link" to="/departments">
                                    Departments
                                    <Icon icon="octicon:triangle-down-24" />
                                </NavLink>
                                <div id="mega_drop_down">
                                    <div className="d-block w-100">
                                        <Row xs={1} md={2} lg={4} gap={3}>
                                            <Col className="drop_down_content">
                                                <ul>
                                                    {departments?.map(
                                                        (item, index) => {
                                                            if (index < 7) {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <Link
                                                                            to={`/departments/${item.slug}`}
                                                                            className="link"
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </Link>
                                                                    </li>
                                                                );
                                                            }
                                                        }
                                                    )}
                                                </ul>
                                            </Col>
                                            <Col className="drop_down_content">
                                                <ul>
                                                    {departments?.map(
                                                        (item, index) => {
                                                            if (
                                                                index >= 7 &&
                                                                index < 14
                                                            ) {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <Link
                                                                            to={`/departments/${item.slug}`}
                                                                            className="link"
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </Link>
                                                                    </li>
                                                                );
                                                            }
                                                        }
                                                    )}
                                                </ul>
                                            </Col>
                                            <Col className="drop_down_content">
                                                <ul>
                                                    {departments?.map(
                                                        (item, index) => {
                                                            if (
                                                                index >= 14 &&
                                                                index < 21
                                                            ) {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <Link
                                                                            to={`/departments/${item.slug}`}
                                                                            className="link"
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </Link>
                                                                    </li>
                                                                );
                                                            }
                                                        }
                                                    )}
                                                </ul>
                                            </Col>
                                            <Col className="drop_down_content">
                                                <ul>
                                                    {departments?.map(
                                                        (item, index) => {
                                                            if (
                                                                index >= 21 &&
                                                                index < 28
                                                            ) {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <Link
                                                                            to={`/departments/${item.slug}`}
                                                                            className="link"
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </Link>
                                                                    </li>
                                                                );
                                                            }
                                                        }
                                                    )}
                                                </ul>
                                            </Col>
                                            <Col className="drop_down_content">
                                                <ul></ul>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Nav.Item>
                            {/* menu for tablet and mobile */}
                            <Nav.Item className="d-lg-none">
                                <Link
                                    className="nav-link"
                                    onClick={() =>
                                        setDepartmentMenuCollapse(
                                            !departmentMenuCollapse
                                        )
                                    }
                                    aria-controls="department_menu_collapse"
                                    aria-expanded={departmentMenuCollapse}
                                >
                                    Departments
                                    <Icon icon="octicon:triangle-down-24" />
                                </Link>
                                <Collapse in={departmentMenuCollapse}>
                                    <div
                                        id="department_menu_collapse"
                                        className="px-3"
                                    >
                                        <div id="mega_drop_down">
                                            <div className="d-block w-100">
                                                <Row
                                                    xs={1}
                                                    md={2}
                                                    lg={4}
                                                    gap={3}
                                                >
                                                    <Col className="drop_down_content">
                                                        <ul>
                                                            {departments?.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    if (
                                                                        index <
                                                                        7
                                                                    ) {
                                                                        return (
                                                                            <li
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                <Link
                                                                                    to={`/departments/${item.slug}`}
                                                                                    className="link"
                                                                                >
                                                                                    {
                                                                                        item.name
                                                                                    }
                                                                                </Link>
                                                                            </li>
                                                                        );
                                                                    }
                                                                }
                                                            )}
                                                        </ul>
                                                    </Col>
                                                    <Col className="drop_down_content">
                                                        <ul>
                                                            {departments?.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    if (
                                                                        index >=
                                                                            7 &&
                                                                        index <
                                                                            14
                                                                    ) {
                                                                        return (
                                                                            <li
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                <Link
                                                                                    to={`/departments/${item.slug}`}
                                                                                    className="link"
                                                                                >
                                                                                    {
                                                                                        item.name
                                                                                    }
                                                                                </Link>
                                                                            </li>
                                                                        );
                                                                    }
                                                                }
                                                            )}
                                                        </ul>
                                                    </Col>
                                                    <Col className="drop_down_content">
                                                        <ul>
                                                            {departments?.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    if (
                                                                        index >=
                                                                            14 &&
                                                                        index <
                                                                            21
                                                                    ) {
                                                                        return (
                                                                            <li
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                <Link
                                                                                    to={`/departments/${item.slug}`}
                                                                                    className="link"
                                                                                >
                                                                                    {
                                                                                        item.name
                                                                                    }
                                                                                </Link>
                                                                            </li>
                                                                        );
                                                                    }
                                                                }
                                                            )}
                                                        </ul>
                                                    </Col>
                                                    <Col className="drop_down_content">
                                                        <ul>
                                                            {departments?.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    if (
                                                                        index >=
                                                                            21 &&
                                                                        index <
                                                                            28
                                                                    ) {
                                                                        return (
                                                                            <li
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                <Link
                                                                                    to={`/departments/${item.slug}`}
                                                                                    className="link"
                                                                                >
                                                                                    {
                                                                                        item.name
                                                                                    }
                                                                                </Link>
                                                                            </li>
                                                                        );
                                                                    }
                                                                }
                                                            )}
                                                        </ul>
                                                    </Col>
                                                    <Col className="drop_down_content">
                                                        <ul></ul>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </Collapse>
                            </Nav.Item>

                            <Nav.Item>
                                <NavLink
                                    className="nav-link"
                                    to="/find-a-doctor"
                                >
                                    Find Doctors
                                </NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink className="nav-link" to="/facilities">
                                    Facilities
                                </NavLink>
                            </Nav.Item>
                            <Nav.Item className="dropdown_menu d-none d-lg-block">
                                <Nav.Link className="border-0 pe-0">
                                    For Patient{" "}
                                    <Icon icon="octicon:triangle-down-24" />
                                </Nav.Link>
                                <div id="drop_down">
                                    <ul>
                                        <li>
                                            <Link to="/" className="link">
                                                In-Patient Feedback
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/" className="link">
                                                Customer Satisfaction Feedback
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/" className="link">
                                                Visitors Policy
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/" className="link">
                                                Packages
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/" className="link">
                                                Room Rent
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/" className="link">
                                                Equipment’s
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </Nav.Item>

                            {/* menu for tablet and mobile */}
                            <Nav.Item className="d-lg-none">
                                <Link
                                    className="nav-link"
                                    onClick={() =>
                                        setPatientMenuCollapse(
                                            !patientMenuCollapse
                                        )
                                    }
                                    aria-controls="example-collapse-text"
                                    aria-expanded={patientMenuCollapse}
                                >
                                    For Patient{" "}
                                    <Icon icon="octicon:triangle-down-24" />
                                </Link>
                                <Collapse in={patientMenuCollapse}>
                                    <div id="example-collapse-text">
                                        <ul className="down_content px-3">
                                            <li>
                                                <Link to="/" className="link">
                                                    Appointment Desk{" "}
                                                </Link>
                                            </li>

                                            <li>
                                                <Link to="/" className="link">
                                                    ATM{" "}
                                                </Link>
                                            </li>

                                            <li>
                                                <Link to="/" className="link">
                                                    Birth Certificate{" "}
                                                </Link>
                                            </li>

                                            <li>
                                                <Link to="/" className="link">
                                                    Blood Bank{" "}
                                                </Link>
                                            </li>

                                            <li>
                                                <Link to="/" className="link">
                                                    Cafeteria{" "}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </Collapse>
                            </Nav.Item>

                            {/* Info and contact */}
                            <div className="d-md-none d-xs-flex">
                                <NavLink to="/about" className="nav-link">
                                    About
                                </NavLink>
                                <NavLink to="/blog" className="nav-link">
                                    Media
                                </NavLink>
                                <NavLink to="/career" className="nav-link">
                                    Career
                                </NavLink>
                                <NavLink to="/gallery" className="nav-link">
                                    Gallery
                                </NavLink>
                                <NavLink to="/contact" className="nav-link">
                                    Contact
                                </NavLink>
                            </div>

                            <div className="top_info_contact d-md-none d-xs-flex me-auto me-md-0">
                                <p className="mb-xs-3 pb-2 p-md-0">
                                    <Icon
                                        icon="ph:phone"
                                        className="contact_icon "
                                    />
                                    +88 02 222296733
                                </p>
                                <p className="ml-md-2 pb-1 p-md-0">
                                    <Icon
                                        icon="mdi:email-outline"
                                        className="contact_icon"
                                    />
                                    dhakahealthcaresystems@gmail.com
                                </p>
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Container>
        </>
    );
};

export default NavigationBar;
