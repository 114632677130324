import { Icon } from '@iconify/react';
import React from 'react';
import { Link } from 'react-router-dom';
import TopInfoBar from '../../components/top_info_bar/TopInfoBar';
import Footer from '../../components/footer/Footer';
import NavigationBar from "../../components/top_navigation_bar/NavigationBar";
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import { Container } from 'react-bootstrap-v5';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

const Career = () => {
    return (
        <div>
            <Helmet>
                <title>Career - Dhaka Health Care</title>
            </Helmet>
            <TopInfoBar />
            <NavigationBar />
            <Breadcrumb>
                <h1>Career</h1>
                <ul className="breadcrumb">
                    <li>
                        <Link to="/" className="d-flex align-items-end">
                            <Icon
                                className="icon"
                                icon="healthicons:home-alt-outline"
                            />{" "}
                            Home
                        </Link>
                    </li>
                    <li className="active">Career</li>
                </ul>
            </Breadcrumb>

            <Container className="overview sec-padding">
                <div className="container">
                    <div className="row pl-2">
                        <div className="col-12 col-lg-5">
                            <div className="overImg">
                                <div className="img-card">
                                    <img
                                        className="w-100"
                                        src="../image/career.jpg"
                                        alt="Banner"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-7 pt-5 pt-lg-0 mx-auto">
                            <h1 className="fw-bold">
                                Join Our Team and Make a Difference in
                                Healthcare
                            </h1>
                            <p className="pt-3 mb-3">
                                At Dhaka HealthCare Systems Hospital, we are
                                more than just a healthcare facility – we are a
                                close-knit community of passionate professionals
                                driven by a common purpose: to improve lives
                                through exceptional healthcare. As you consider
                                your career options, here are some compelling
                                reasons to join our team:
                            </p>
                            <div className="readmores">
                                We foster a culture of compassion and support,
                                where every member of our team is valued and
                                respected. Whether you are a seasoned healthcare
                                professional or starting your career, you will
                                find a welcoming and inclusive atmosphere that
                                encourages collaboration and growth
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <div className="career-featured-area mb-120">
                <div className="container" id="job-opening">
                    <div className="row mb-60">
                        <div className="col-12 d-flex flex-wrap align-items-end justify-content-md-between justify-content-start gap-3">
                            <div className="section-title1">
                                <h2>
                                    Latest <span>Featured</span> Jobs
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row g-4">
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="feature-card">
                                <div className="company-area">
                                    <div className="company-details">
                                        <div className="name-location">
                                            <h5>Pharmacist</h5>
                                            <p>Full Time</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="job-discription">
                                    <ul>
                                        <li>
                                            <i className="fa-light fa-arrow-right"></i>
                                            <p>
                                                <span className="title">
                                                    Vacancy:
                                                </span>{" "}
                                                <span> 04 Person (Both)</span>
                                            </p>
                                        </li>
                                        <li>
                                            <i className="fa-light fa-arrow-right"></i>
                                            <p>
                                                <span className="title">
                                                    Deadline:
                                                </span>{" "}
                                                <span> 15 apr, 2023</span>
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="career_cv_form" id="cv-upload">
                <div className="contact-area sec-padding">
                    <div className="container">
                        <div className="contact-items">
                            <div className="row align-items-center">
                                <div className="col-lg-7 contact-box mb-6">
                                    <div className="form-items">
                                        <h2>
                                            Drop your CV for <br />
                                            Future Job opportunity's.
                                        </h2>
                                        <form
                                            action="#"
                                            method="POST"
                                            className="contact-form"
                                        >
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control"
                                                            id="name"
                                                            name="name"
                                                            placeholder="Name"
                                                            type="text"
                                                        />
                                                        <span className="alert-error"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control"
                                                            id="email"
                                                            name="email"
                                                            placeholder="Email*"
                                                            type="email"
                                                        />
                                                        <span className="alert-error"></span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control"
                                                            id="phone"
                                                            name="phone"
                                                            placeholder="Phone"
                                                            type="text"
                                                        />
                                                        <span className="alert-error"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group comments">
                                                        <textarea
                                                            className="form-control"
                                                            id="comments"
                                                            name="comments"
                                                            placeholder="Your Message*"
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group comments">
                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            id="cv"
                                                            name="cv"
                                                            placeholder="Attach Your CV"
                                                        ></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <button
                                                        href="#"
                                                        className="common_btn"
                                                        target="_blank"
                                                    >
                                                        Send CV
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div className="col-lg-5 left-info">
                                    <Row
                                        xs={1}
                                        md={3}
                                        lg={1}
                                        className="info-items pt-3 pt-lg-0"
                                    >
                                        {/* <!-- Single Item --> */}
                                        <Col className="item">
                                            {/* <span className="icon"> */}{" "}
                                            {/* </span> */}
                                            <div className="info">
                                                <div className="d-flex align-items-center">
                                                    <Icon
                                                        className="icon me-2"
                                                        icon="material-symbols:location-on-outline"
                                                    />
                                                    <h5 className="m-0">
                                                        Location
                                                    </h5>
                                                </div>
                                                <p>
                                                    House # 07, Road-08, Madani
                                                    Avenue (100 Feet), Vatara,
                                                    Dhaka-1212.
                                                </p>
                                            </div>
                                        </Col>
                                        {/* <!-- End Single Item --> */}
                                        {/* <!-- Single Item --> */}
                                        <Col className="item">
                                            <div className="info">
                                                <div className="d-flex align-items-center">
                                                    <Icon
                                                        className="icon me-2"
                                                        icon="tabler:phone-call"
                                                    />{" "}
                                                    <h5 className="m-0">
                                                        Make a Call
                                                    </h5>
                                                </div>
                                                <p>
                                                    +88 02 2222733 <br /> +88 02
                                                    2222790
                                                </p>
                                            </div>
                                        </Col>
                                        {/* <!-- End Single Item --> */}
                                        {/* <!-- Single Item --> */}
                                        <Col className="item">
                                            <div className="info">
                                                <div className="d-flex align-items-center">
                                                    <Icon
                                                        className="icon me-2"
                                                        icon="ic:outline-email"
                                                    />{" "}
                                                    <h5 className="m-0">
                                                        Send a Mail
                                                    </h5>
                                                </div>
                                                <p>
                                                    dhakahealthcaresystems@gmail.com
                                                </p>
                                            </div>
                                        </Col>
                                        {/* <!-- End Single Item --> */}
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Career;