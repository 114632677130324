import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Footer from '../../components/footer/Footer';
import TopInfoBar from '../../components/top_info_bar/TopInfoBar';
import NavigationBar from '../../components/top_navigation_bar/NavigationBar';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';

const Facilities = () => {
    
    const [services, setServices] = useState([]);

    // Load Available Departments
    useEffect(() => {
        window.axios
            .get("/api/services")
            .then((resp) => {
                if (resp.status === 200) {
                    setServices(resp.data.data);
                }
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data?.message);
                }
            });
    }, []);

    const mocFacilities = [
        {
            id: 1,
            title: "BED & ROOM CATEGORIES",
            image: "../image/feature/feature8.jpg",
            detailsImage: ["../image/feature/hospital5.jpg"],
            slug: "bedandrooms",
            description:
                "Our support to the community by offering Health Screening for prevention and detection of diseases saves one from the burden of disease load by early",
        },
        {
            id: 2,
            title: "NURSING CARE",
            image: "../image/feature/medicine.jpg",
            detailsImage: [],
            slug: "nursingcare",
            description:
                "Health Screening for prevention and detection of diseases saves one from the burden of disease load by early",
        },
    ];

    return (
        <div>
            <Helmet>
                <title>Facilities - Dhaka Health Care</title>
            </Helmet>
            <TopInfoBar />
            <NavigationBar />
            <Breadcrumb>
                <h1>Facilities</h1>
                <ul className="breadcrumb">
                    <li>
                        <Link to="/" className="d-flex align-items-end">
                            <Icon
                                className="icon"
                                icon="healthicons:home-alt-outline"
                            />{" "}
                            Home
                        </Link>
                    </li>
                    <li className="active">Facilities</li>
                </ul>
            </Breadcrumb>

            <div className="container-lg">
                <div className="service-details-area pb-5">
                    <div className="row mt-5">
                        <div className="col-lg-8 col-md-7">
                            <div className="facilities-area full-blog">
                                <div className="container">
                                    <div className="facilities-items">
                                        <div className="row">
                                            {/* Single Item */}
                                            {mocFacilities?.map(
                                                (fac, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className="col-lg-6 col-md-6 single-item"
                                                        >
                                                            <div
                                                                className="item"
                                                                style={{
                                                                    height: "500px",
                                                                }}
                                                            >
                                                                <div className="thumb">
                                                                    <img
                                                                        src={
                                                                            fac.image
                                                                        }
                                                                        alt="Thumb"
                                                                        className="img-fluid"
                                                                    />
                                                                </div>
                                                                <div className="info">
                                                                    <h4>
                                                                        <Link
                                                                            to={
                                                                                fac.slug
                                                                            }
                                                                        >
                                                                            {fac
                                                                                .title
                                                                                .length >
                                                                            50
                                                                                ? `${fac.title.slice(
                                                                                      0,
                                                                                      50
                                                                                  )}...`
                                                                                : fac.title}
                                                                        </Link>
                                                                    </h4>
                                                                    <p>
                                                                        <span>
                                                                            {fac
                                                                                .description
                                                                                .length >
                                                                            100
                                                                                ? `${fac.description.slice(
                                                                                      0,
                                                                                      100
                                                                                  )}...`
                                                                                : fac.description}
                                                                        </span>
                                                                    </p>
                                                                    <Link
                                                                        to={`/facilities/${fac.slug}`}
                                                                        className="details-link"
                                                                    >
                                                                        More In
                                                                        Details
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Sidebar */}
                        <div className="col-lg-4 col-md-5 ">
                            <div
                                className="p-3 rounded-2"
                                style={{
                                    backgroundColor: "#f4f4f4",
                                    height: "660px",
                                }}
                            >
                                <div className="promo-box-bg home-emergency">
                                    <div className="promo-service-top row">
                                        <img
                                            className="img-fluid img-responsive promo-service-img me-0 col-5 col-md-6"
                                            src="../image/abbulance.jpg"
                                        />
                                        <div className="emergency-rightsidebar col-7 col-md-6 me-0 ps-0">
                                            <p className="amb-caller">
                                                <Link to="/services/emergency">
                                                    Emergency?
                                                </Link>
                                            </p>
                                            <p className="amb-call-ambulance">
                                                Call Ambulance <br />
                                            </p>
                                            <p className="amb-call-number">
                                                +88 02 222296733
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="sidebar-item">
                                    <h5 className="category-list-link open-cat-list">
                                        select Departments{" "}
                                        <i
                                            id="cat-arrow"
                                            className="fa fa-angle-down"
                                        ></i>
                                    </h5>
                                    <ul
                                        className="services-list list-unstyled p-2"
                                        style={{
                                            height: "400px",
                                            overflowY: "scroll",
                                        }}
                                    >
                                        {services?.map((dep, index) => {
                                            return (
                                                <li
                                                    key={index}
                                                    className="d-block w-full"
                                                >
                                                    <Link
                                                        className="active d-block w-full"
                                                        to={`/services/${dep.slug}`}
                                                    >
                                                        {dep.title}
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Facilities;