import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap-v5";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import Footer from "../../components/footer/Footer";
import TopInfoBar from "../../components/top_info_bar/TopInfoBar";
import NavigationBar from "../../components/top_navigation_bar/NavigationBar";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { Audio } from "react-loader-spinner";

const Departments = () => {
    const [departments, setDepartments] = useState([]);
    const [loading, setLoading] = useState(false);
    
    // Load Available Departments
    useEffect(() => {
        setLoading(true);
        window.axios
            .get("/api/departments")
            .then((resp) => {
                if (resp.status === 200) {
                    setDepartments(resp.data.data);
                    setLoading(false);
                }
            })
            .catch((error) => {
                if (error.response) {
                    setLoading(false);
                    toast.error(error.response.data?.message);
                }
            });
    }, []);

    return (
        <div className="departments">
            <Helmet>
                <title>Departments - Dhaka Health Care</title>
            </Helmet>
            <TopInfoBar />
            <NavigationBar />
            <Breadcrumb>
                <h1>Departments</h1>
                <ul className="breadcrumb">
                    <li className="">
                        <Link to="/" className="d-flex align-items-end">
                            <Icon
                                className="icon"
                                icon="healthicons:home-alt-outline"
                            />{" "}
                            Home
                        </Link>
                    </li>
                    <li className="active">Departments</li>
                </ul>
            </Breadcrumb>

            <Container className="py-5">
                <div className="section-title">
                    <h3>Our Departments</h3>
                </div>
                {loading ? (
                    <div>
                        <Audio
                            height="50"
                            width="50"
                            color="orange"
                            ariaLabel="audio-loading"
                            wrapperStyle={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                            wrapperClass="wrapper-class"
                            visible={true}
                        />
                    </div>
                ) : !loading && departments?.length <= 0 ? (
                    <div className="d-flex justify-content-center align-items-center">
                        <p className="text-red">No Departments Found</p>
                    </div>
                ) : (
                    <div className="departments_list">
                        <div className="d-block w-100">
                            <ul>
                                {departments?.map((department, index) => {
                                    return (
                                        <li key={index}>
                                            <Link
                                                className="department_link"
                                                to={`/departments/${department.slug}`}
                                            >
                                                {department.name}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                )}
            </Container>
            <Footer />
        </div>
    );
};

export default Departments;
