import React from 'react';
import { Container } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap-v5';
import { Link } from 'react-router-dom';

const CallToAction = () => {
    
    return (
        <>
            <div className="call_to_action_background mb-5">
                <Container className="call_to_action_content">
                    <Row xs={1} md={3} className="gy-4">
                        <Col>
                            <Link to="/blog" className="btn">
                                <span>
                                    <span>
                                        <span>CASE STUDY</span>
                                    </span>
                                </span>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/contact" className="btn">
                                <span>
                                    <span>
                                        <span>ASK A QUESTION</span>
                                    </span>
                                </span>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/contact" className="btn">
                                <span>
                                    <span>
                                        <span>SHARE EXPERIENCE</span>
                                    </span>
                                </span>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default CallToAction;