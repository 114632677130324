import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SectionHeader = ({ title, details, button_text, url }) => {
    return (
        <Container className="section_head_contain">
            <div className="section_header">
                <h2>{title}</h2>
                <p className="m-0 lh-sm">{details}</p>
            </div>
            <div className="section_head_btn">
                {button_text && (
                    <Link className="common_btn" to={url}>
                        {button_text}
                    </Link>
                )}
            </div>
        </Container>
    );
};

export default SectionHeader;