import React, { useEffect, useState } from 'react';
import Footer from '../components/footer/Footer';
import TopInfoBar from '../components/top_info_bar/TopInfoBar';
import NavigationBar from '../components/top_navigation_bar/NavigationBar';
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';

const About = () => {
    const [partners, setPartners] = useState([])
    const [loading, setLoading] = useState(false)

    // Load Home Data
    useEffect(() => {
        setLoading(true);
        window.axios
            .get(`/api/home`)
            .then((resp) => {
                setPartners(resp.data.data.partners);
                setLoading(false);
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data?.message);
                    setLoading(false);
                }
            });
    }, []);
    return (
        <>
            <Helmet>
                <title>About - Dhaka Health Care</title>
            </Helmet>
            <TopInfoBar />
            <NavigationBar />
            <Breadcrumb>
                <h1>About US</h1>
                <ul className="breadcrumb">
                    <li>
                        <Link to="/" className="d-flex align-items-end">
                            <Icon
                                className="icon"
                                icon="healthicons:home-alt-outline"
                            />{" "}
                            Home
                        </Link>
                    </li>
                    <li className="active">About US</li>
                </ul>
            </Breadcrumb>

            <div className="about-area overflow-hidden sec-padding">
                <div className="container">
                    <div className="about-items">
                        <div className="row align-items-center">
                            {/* <!-- Thumb --> */}
                            <div className="col-lg-6">
                                <div className="thumb">
                                    <img
                                        src="../image/about2.jpg"
                                        alt="Thumb"
                                        className="img-fluid"
                                    />
                                    <div className="fun-fact">
                                        <div className="counter">
                                            <div
                                                className="timer"
                                                data-to="26"
                                                data-speed="2000"
                                            >
                                                20
                                            </div>
                                            <div className="operator">
                                                Years
                                            </div>
                                        </div>
                                        <span className="medium">
                                            Professional Experience
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Thumb --> */}

                            <div className="col-lg-6">
                                <div className="content">
                                    <h2>
                                        Welcome to Dhaka Health Care - Your Path
                                        to Health and Wellness
                                    </h2>
                                    <p>
                                        At Dhaka Health Care, our mission is to
                                        provide compassionate, comprehensive,
                                        and cutting-edge healthcare services to
                                        the community of Dhaka and beyond. We
                                        are dedicated to ensuring the well-being
                                        of our patients, placing their health
                                        and comfort at the forefront of
                                        everything we do. As a premier
                                        healthcare facility, we take pride in
                                        our state-of-the-art infrastructure,
                                        highly skilled medical professionals,
                                        and patient-centric approach.
                                    </p>
                                    <ul>
                                        <li>
                                            <h5>
                                                A Caring and Compassionate
                                                Environment:
                                            </h5>
                                            <p>
                                                As you step into Dhaka Health
                                                Care, you will immediately sense
                                                the warmth and compassion that
                                                permeates our hospital's
                                                atmosphere. Our team of caring
                                                and dedicated staff is committed
                                                to making your healthcare
                                                experience as comfortable and
                                                stress-free as possible.
                                            </p>
                                        </li>
                                        <li>
                                            <h5>
                                                Cutting-Edge Technology and
                                                Expert Medical Care:
                                            </h5>
                                            <p>
                                                Equipped with the latest
                                                advancements in medical
                                                technology, our hospital boasts
                                                state-of-the-art facilities and
                                                equipment. This enables our
                                                highly qualified team of
                                                doctors, surgeons, nurses, and
                                                support staff to deliver the
                                                highest standard of medical care
                                                across various specialties at
                                                Dhaka Health Care.
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="brand-area bg-gray clients-area text-light">
                <div className="container">
                    <div className="brand-items client-items">
                        <div className="row align-center">
                            <div className="col-lg-6 info">
                                <h2>Affiliate partners</h2>
                                <p>
                                    Dhaka Health Care has almost all departments
                                    of medical service under one roof which
                                    enable us to deliver proper integrated
                                    services to our patients.
                                </p>
                            </div>
                        </div>
                        <div className="row align-center">
                            {
                                partners.map((item, index) => {
                                    return (
                                        <div className="col-lg-3 brand">
                                            <div className="partner-box">
                                                <div className="item">
                                                    <Link to={item.url}>
                                                        <img
                                                            src={item.image}
                                                            alt="Partners Image"
                                                        />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                            {/* <div className="col-lg-3 brand">
                                <div className="partner-box">
                                    <div className="item">
                                        <img
                                            src="../image/client_logo/2.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 brand">
                                <div className="partner-box">
                                    <div className="item">
                                        <img
                                            src="../image/client_logo/3.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 brand">
                                <div className="partner-box">
                                    <div className="item">
                                        <img
                                            src="../image/client_logo/4.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 brand">
                                <div className="partner-box">
                                    <div className="item">
                                        <img
                                            src="../image/client_logo/5.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 brand">
                                <div className="partner-box">
                                    <div className="item">
                                        <img
                                            src="../image/client_logo/4.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="achivement-area sec-padding">
                {/* <!-- Fixed BG --> */}
                <div className="fixed-bg">
                    {/* <img src="../image/border-bg.jpg" alt="Shape" /> */}
                </div>
                {/* <!-- Fixed BG --> */}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 heading-side">
                            <h5>Experts in field</h5>
                            <h2>WE ARE COMMITTED TO:</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <div className="single-prevention">
                                <i className="fal fa-check"></i>
                                <h3>Patient-Centered Care</h3>
                                <p>
                                    You are at the heart of everything we do.
                                    Our patient-centered approach means that we
                                    focus on your unique needs, concerns, and
                                    preferences. We listen attentively to your
                                    health goals and collaborate with you to
                                    develop tailored treatment plans that align
                                    with your aspirations for a healthier life
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <div className="single-prevention">
                                <i className="fal fa-check"></i>
                                <h3>Experienced and Caring Team</h3>
                                <p>
                                    Our team of highly skilled and dedicated
                                    healthcare professionals is the backbone of
                                    our hospital. From our compassionate
                                    doctors, nurses, and support staff to our
                                    administrative personnel, we work cohesively
                                    to ensure a seamless healthcare experience
                                    for you.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <div className="single-prevention">
                                <i className="fal fa-check"></i>
                                <h3>Quality and Safety</h3>
                                <p>
                                    Your safety is our priority. We uphold the
                                    highest standards of healthcare quality and
                                    safety throughout our hospital. From
                                    stringent infection control measures to
                                    rigorous safety protocols, we leave no stone
                                    unturned in ensuring a secure and hygienic
                                    environment for your medical journey.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default About;