import React from "react"; 'mdb-react-ui-kit';
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
const Footer = () => {
    return (
        <div>
            <div
                style={{ backgroundColor: "#02590f", color: "white" }}
                className="pt-3"
            >
                <section>
                    <Container className="text-center text-md-start mt-5">
                        <Row className="mt-3 footer-content">
                            <Col
                                md="6"
                                lg="6"
                                xl="2"
                                className="text-left mb-4"
                            >
                                <h6 className="text-uppercase  mb-4">
                                    Dhaka Healthcare System Hospital
                                </h6>
                                <p>
                                    House # 07, Road-08, Madani Avenue (100
                                    Feet), Vatara, Dhaka-1212.
                                </p>
                            </Col>

                            <Col
                                md="3"
                                lg="3"
                                xl="2"
                                className="media_and_gallery text-left mb-4"
                            >
                                <h6 className="text-uppercase  mb-4">
                                    SOCIAL PAGES
                                </h6>
                                <div>
                                    <p>
                                        <Link to="" className="text-reset">
                                            Brief History
                                        </Link>
                                    </p>
                                    <p>
                                        <Link to="" className="text-reset">
                                            Mission Statement
                                        </Link>
                                    </p>
                                    <p>
                                        <Link to="" className="text-reset">
                                            Board Members
                                        </Link>
                                    </p>
                                    <p>
                                        <Link to="" className="text-reset">
                                            People & Diversity
                                        </Link>
                                    </p>
                                    <p>
                                        <Link to="" className="text-reset">
                                            Operation Team
                                        </Link>
                                    </p>
                                </div>

                                <h6 className="text-uppercase  my-3">
                                    GALLERY
                                </h6>
                                <div className="d-flex flex-column">
                                    <p>
                                        <Link
                                            to="/gallery"
                                            className="text-reset"
                                        >
                                            Photo Gallery
                                        </Link>
                                    </p>
                                </div>
                            </Col>
                            <Col
                                md="3"
                                lg="3"
                                xl="2"
                                className="career_and_appointment text-left mb-4"
                            >
                                <h6 className="text-uppercase  mb-4">CAREER</h6>
                                <div>
                                    <p>
                                        <HashLink
                                            smooth="true"
                                            to="/career#job-opening"
                                            className="text-reset"
                                        >
                                            Job Openings
                                        </HashLink>
                                    </p>
                                    <p>
                                        <Link
                                            smooth="true"
                                            to="/career#cv-upload"
                                            className="text-reset"
                                        >
                                            Application Form
                                        </Link>
                                    </p>
                                    <p>
                                        <HashLink
                                            smooth="true"
                                            to="/career#cv-upload"
                                            className="text-reset"
                                        >
                                            CV Upload
                                        </HashLink>
                                    </p>
                                </div>

                                <h6 className="text-uppercase  my-4">
                                    CONTACT
                                </h6>
                                <div>
                                    <p>
                                        <Link
                                            to="/login"
                                            className="text-reset"
                                        >
                                            Login
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            to="/about"
                                            className="text-reset"
                                        >
                                            Basic Information
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            to="/contact"
                                            className="text-reset"
                                        >
                                            Location Map
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            to="/contact"
                                            className="text-reset"
                                        >
                                            Write to us
                                        </Link>
                                    </p>
                                </div>

                                <h6 className="text-uppercase  my-3">
                                    APPOINTMENT PHONE NUMBER:
                                </h6>
                                <div className="d-flex flex-column">
                                    <p>01304 769 554</p>
                                    <p>01320 391 708</p>
                                </div>
                            </Col>

                            <Col
                                md="8"
                                lg="9"
                                xl="3"
                                className=" text-left mb-4"
                            >
                                <h6 className="text-uppercase text-center  mb-4">
                                    Our Location
                                </h6>
                                <section
                                    className="mx-auto"
                                    style={{ maxWidth: "23rem" }}
                                >
                                    <div className="card map-card">
                                        <div
                                            id="map-container-google-1"
                                            className="z-depth-1-half map-container"
                                            style={{ height: "380px" }}
                                        >
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3650.5654928677386!2d90.43282459999999!3d23.7984823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c7a6c543790d%3A0xc7381d2b558acb7a!2sDhaka%20Healthcare%20Systems%20Hospital!5e0!3m2!1sen!2sbd!4v1683804822636!5m2!1sen!2sbd"
                                                width="100%"
                                                height="100%"
                                                style={{ border: "0" }}
                                                allowFullScreen=""
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        </div>
                                        <div
                                        >
                                            <div className="button px-2 mt-3">
                                                <Link
                                                    className="btn btn-floating btn-lg living-coral text-white float-end"
                                                    style={{
                                                        marginRight: ".75rem",
                                                    }}
                                                >
                                                    <i className="fas fa-bicycle"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </Col>

                            <Col
                                md="4"
                                lg="3"
                                xl="3"
                                className="text-left mb-md-0 mb-4"
                            >
                                <h6 className="text-uppercase  mb-4">
                                    EMERGENCY
                                </h6>
                                <div>
                                    <Icon
                                        icon="ph:phone-call"
                                        className="me-1"
                                    />
                                    +88 02 222296733
                                </div>
                                <div>
                                    <Icon
                                        icon="ph:phone-call"
                                        className="me-1"
                                    />
                                    +88 02 222296790
                                </div>
                                <h6 className="text-uppercase  my-3">
                                    SOCIAL PAGES
                                </h6>
                                <div className="d-flex justify-content-center justify-content-md-start">
                                    <Link
                                        target="_blank"
                                        to="https://www.facebook.com/DHSDhaka/"
                                        className="bg-transparent border-0"
                                    >
                                        <Icon
                                            className="me-2 fs-4"
                                            icon="logos:facebook"
                                            link="www.facebook.com/dhsdhaka"
                                        />
                                    </Link>
                                    <Link
                                        target="_blank"
                                        to="https://www.instagram.com/dhs.dhaka"
                                        className="bg-transparent border-0"
                                    >
                                        <Icon
                                            className="me-2 fs-4"
                                            icon="skill-icons:instagram"
                                            link="www.instagram.com/dhs.dhaka"
                                        />
                                    </Link>
                                </div>
                                <div className="footer_terms">
                                    <Link
                                        target="_blank"
                                        to="/"
                                        className="bg-transparent border-0"
                                    >
                                        Terms & Conditions
                                    </Link>
                                    <Link
                                        target="_blank"
                                        to="/"
                                        className="bg-transparent border-0"
                                    >
                                        Disclaimer
                                    </Link>
                                    <Link
                                        target="_blank"
                                        to="/"
                                        className="bg-transparent border-0"
                                    >
                                        Sitemap
                                    </Link>
                                    <Link
                                        target="_blank"
                                        to="/"
                                        className="bg-transparent border-0"
                                    >
                                        Webmail
                                    </Link>
                                    <Link
                                        target="_blank"
                                        to="/"
                                        className="bg-transparent border-0"
                                    >
                                        Shajalal Group
                                    </Link>
                                </div>
                                <p className="mt-4"></p>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <div
                    className="text-center p-4"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.08)" }}
                >
                    © 2023 Dhaka Healthcare Systems Hospital. All Rights
                    Reserved. Design & Developed by{" "}
                    <Link
                        className="text-reset "
                        to="https://www.ajratech.com/"
                    >
                        AJRA
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Footer;