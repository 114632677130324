import React, { useEffect, useState } from 'react';
import { Container } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { toast } from 'react-toastify';

const Partners = ({ partners }) => {

    const settings = {
        dots: true,
        speed: 1000,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        nextArrow: <></>,
        prevArrow: <></>,
        // infinite: true,
        // autoplay: true,
        // autoplaySpeed: 2000,
        // cssEase: "linear",
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <Container className="py-5">
            <h3 className="section_title">AFFILIATED PARTNERS</h3>
            <div className="partners_contain slide_custom_dots pt-4 pb-5">
                <Slider {...settings}>
                    {partners?.map((partner, index) => (
                        <div key={index}>
                            <Link to={partner.url} target="_blank">
                                <img
                                    className="img-fluid mx-auto border border-1 border-dark px-4 py-2"
                                    src={partner.image}
                                    alt=""
                                />
                            </Link>
                        </div>
                    ))}
                </Slider>
            </div>
        </Container>
    );
};

export default Partners;