import React from 'react';
import { Route, Routes } from 'react-router-dom';
import About from './pages/About';
import Appointment from './pages/appointment/Appointment';
import Blog from './pages/blog/Blog';
import BlogDetails from './pages/blog/BlogDetails';
import Career from './pages/career/Career';
import Contact from './pages/contact/Contact';
import DepartmentDetails from './pages/departments/DepartmentDetails';
import Departments from './pages/departments/Departments';
import Facilities from './pages/facilities/Facilities';
import DoctorDetails from './pages/find_a_doctor/DoctorDetails';
import Doctors from './pages/find_a_doctor/Doctors';
import Gallery from './pages/gallery/Gallery';
import Home from './pages/Home';
import Login from './pages/login/Login';
import Services from './pages/services/Services';
import ServiceDetails from './pages/services/ServiceDetails';
import NotFound from './pages/notFound/NotFound';
import FeatureDetails from './components/our_features/FeatureDetails';
import FacilitiesDetails from './pages/facilities/FacilitiesDetails';

const DefinedWebRoutes = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />

                {/* Top Info Bar Routes */}
                <Route path="/about" element={<About />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:slug" element={<BlogDetails />} />
                <Route path="/career" element={<Career />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/contact" element={<Contact />} />

                <Route
                    path="/feature-details/:id"
                    element={<FeatureDetails />}
                />

                {/* Top Navigation Bar Routes */}
                <Route path="/departments" element={<Departments />} />
                <Route
                    path="/departments/:slug"
                    element={<DepartmentDetails />}
                />
                <Route path="/services" element={<Services />} />
                <Route path="/services/:slug" element={<ServiceDetails />} />
                <Route path="/find-a-doctor" element={<Doctors />} />
                <Route path="/doctor/:slug" element={<DoctorDetails />} />
                <Route path="/facilities" element={<Facilities />} />
                <Route
                    path="/facilities/:slug"
                    element={<FacilitiesDetails />}
                />

                {/* Others Routes */}
                <Route path="/appointment" element={<Appointment />} />

                {/* Not Found Page */}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </div>
    );
};

export default DefinedWebRoutes;