import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import Footer from "../../components/footer/Footer";
import TopInfoBar from "../../components/top_info_bar/TopInfoBar";
import NavigationBar from "../../components/top_navigation_bar/NavigationBar";
import { Helmet } from "react-helmet-async";

const FeatureDetails = () => {
    const [featureDetails, setFeatureDetails] = useState({})
    const { id } = useParams()
    
    const mocFeatures = [
        {
            id: "1",
            tooltip: "Ambulance",
            title: " GETTING SAFE EMERGENCY CARE AT OUR Hospital",
            details:
                "The emergency ambulance of Dhaka Healthcare Systems Hospital is always at your doorstep. Our ambulance service is available 24 hour.",
            img: "../image/feature/ambulance.jpg",
        },
        {
            id: "2",
            tooltip: "MEDICINE",
            title: "MEDICINE AND REHABILITATION CARE",
            details:
                "Our Diagnostic expertise are dedicated to Rehabilitation Doctor’s Consultation, Physiotherapy, Occupational Therapy, Speech Therapy and Rehabilitation Nursing",
            img: "../image/feature/medicine.jpg",
        },
        {
            id: "3",
            tooltip: "Equipments",
            title: "Digital MEDICAL Equipments and Systems",
            details:
                "Our commitment is investing in cutting-edge medical technology and advanced equipment that empowers our medical professionals to deliver accurate treatments",
            img: "../image/feature/feature10.jpg",
        },
        {
            id: "4",
            tooltip: "Cabins",
            title: "WE HAVE THE BEST PATIENT CABINS",
            details:
                "Find out whether you will share a room and how your privacy will be maintained. Ask about the hospital policy on visitors to find out the hours.",
            img: "../image/feature/feature8.jpg",
        },
        {
            id: "5",
            tooltip: "Laboratory",
            title: "Digital Laboratory Equipments and Test Reports",
            details:
                "The Laboratory, where a needle is used to take blood from a vein. Usually, this is laboratory for testing Patient Blood and Urine, and the results of these tests",
            img: "../image/feature/feature9.jpg",
        },
        {
            id: "6",
            tooltip: "Waiting Space",
            title: "Comfort Waiting Space for patients and their families",
            details:
                " As you step into our hospital, you will find the patient waiting space thoughtfully designed to Comfort and well-being of our valued patients and their families.",
            img: "../image/feature/feature14.jpg",
        },
    ];

    useEffect(() => {
        const details = mocFeatures.find((item) => item.id === id);
        setFeatureDetails(details);
    },[id])
    return (
        <div>
            <Helmet>
                <title>Feature - Dhaka Health Care</title>
            </Helmet>
            <TopInfoBar />
            <NavigationBar />
            <Breadcrumb>
                <h1>Feature Details</h1>
                <ul className="breadcrumb">
                    <li>
                        <Link to="/" className="d-flex align-items-end">
                            <Icon
                                className="icon"
                                icon="healthicons:home-alt-outline"
                            />{" "}
                            Home
                        </Link>
                    </li>
                    <li className="active">Feature Details</li>
                </ul>
            </Breadcrumb>
            <div
                className="blog-area single full-blog right-sidebar sec-padding"
                style={{ height: "auto" }}
            >
                <div className="container">
                    <div className="blog-items">
                        <div className="row">
                            <div className="blog-content col-lg-8 col-md-12">
                                <div className="blog-item-box">
                                    <div className="item">
                                        {/* <!-- Start Post Thumb --> */}
                                        <div className="thumb">
                                            <img
                                                src={featureDetails?.img}
                                                alt="Thumb"
                                                className="img-fluid w-100"
                                            />
                                            <div className="date">
                                                {featureDetails.tooltip}
                                            </div>
                                        </div>
                                        {/* <!-- Start Post Thumb --> */}

                                        <div className="info">
                                            <h3>{featureDetails.title}</h3>

                                            <p>{featureDetails.details}</p>
                                            {/* <blockquote>
                                                He share of first to worse.
                                                Weddings and any opinions
                                                suitable smallest nay. Houses or
                                                months settle remove ladies
                                                appear. Engrossed suffering
                                                supposing he recommend do
                                                eagerness. Commanded no of
                                                depending.
                                                <cite> - Adam John</cite>
                                            </blockquote>
                                            <h4>
                                                Conduct replied off led whether
                                                any shortly why arrived adapted
                                            </h4>
                                            <ul>
                                                <li>
                                                    Pretty merits waited six
                                                </li>
                                                <li>
                                                    General few civilly amiable
                                                    pleased account carried.
                                                </li>
                                                <li>
                                                    Continue indulged speaking
                                                </li>
                                                <li>
                                                    Narrow formal length my
                                                    highly
                                                </li>
                                                <li>
                                                    Occasional pianoforte
                                                    alteration unaffected
                                                    impossible
                                                </li>
                                            </ul>
                                            <div className="row gallery">
                                                <div className="col-lg-6 col-md-6">
                                                    <img
                                                        src="../image/blog/1.jpg"
                                                        alt="Thumb"
                                                        className="img-fluid"
                                                    />
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <img
                                                        src="../image/blog/2.jpg"
                                                        alt="Thumb"
                                                        className="img-fluid"
                                                    />
                                                </div>
                                            </div>
                                            <p>
                                                Earnestly so do instantly
                                                pretended. See general few
                                                civilly amiable pleased account
                                                carried. Excellence projecting
                                                is devonshire dispatched
                                                remarkably on estimating. Side
                                                in so life past. Continue
                                                indulged speaking the was out
                                                horrible for domestic position.
                                                Seeing rather her you not esteem
                                                men settle genius excuse. Deal
                                                say over you age from.
                                                Comparison new ham melancholy
                                                son themselves. projecting is
                                                devonshire dispatched are
                                            </p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!-- Start Sidebar --> */}
                            <div className="sidebar col-lg-4 col-md-12">
                                <aside>
                                    {/* <div className="sidebar-item search">
                                        <div className="sidebar-info">
                                            <form>
                                                <input
                                                    type="text"
                                                    name="text"
                                                    className="form-control"
                                                />
                                                <button type="submit">
                                                    <Icon icon="ri:search-line" />
                                                </button>
                                            </form>
                                        </div>
                                    </div> */}
                                    <div className="sidebar-item recent-post">
                                        <div className="title">
                                            <h4>Recent Post</h4>
                                        </div>
                                        <ul>
                                            {mocFeatures?.map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <div className="thumb">
                                                            {/* <a href="#"> */}
                                                                <img
                                                                    src={
                                                                        item.img 
                                                                    }
                                                                    alt="Thumb"
                                                                    className="img-fluid"
                                                                />
                                                            {/* </a> */}
                                                        </div>
                                                        <div className="info">
                                                            <div className="meta-title">
                                                                <span className="post-date">
                                                                    {item.tooltip}
                                                                </span>
                                                            </div>
                                                            <Link
                                                                to={`/feature-details/${item.id}`}
                                                            >
                                                                {item.title}
                                                            </Link>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                    <div className="sidebar-item social-sidebar">
                                        <div className="title">
                                            <h4>follow us</h4>
                                        </div>
                                        <div className="sidebar-info">
                                            <ul className="p-0">
                                                <li className="facebook">
                                                    <Link
                                                        to="https://www.facebook.com/DHSDhaka/"
                                                        target="_blank"
                                                    >
                                                        <Icon
                                                            className="fs-3"
                                                            icon="logos:facebook"
                                                        />
                                                    </Link>
                                                </li>
                                                <li className="g-plus">
                                                    <Link
                                                        to="https://www.instagram.com/dhs.dhaka"
                                                        target="_blank"
                                                    >
                                                        <Icon
                                                            className="fs-3"
                                                            icon="skill-icons:instagram"
                                                        />
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                            {/* <!-- End Start Sidebar --> */}
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default FeatureDetails;
